import type { TrelloCardFields } from './mapTrelloCardDataToCard';

export const mapTrelloCardBadgeDataToCardBadge = (
  trelloCardData: TrelloCardFields,
) => {
  return {
    badges: {
      __typename: 'Card_Badges' as const,
      attachments: trelloCardData.badges?.attachments ?? 0,
      attachmentsByType: {
        __typename: 'Card_Badges_AttachmentsByType' as const,
        trello: {
          __typename: 'Card_Badges_AttachmentsByType_Trello' as const,
          board: trelloCardData.badges?.attachmentsByType?.trello?.board ?? 0,
          card: trelloCardData.badges?.attachmentsByType?.trello?.card ?? 0,
        },
      },
      checkItems: trelloCardData.badges?.checkItems ?? 0,
      checkItemsChecked: trelloCardData.badges?.checkItemsChecked ?? 0,
      checkItemsEarliestDue:
        trelloCardData.badges?.checkItemsEarliestDue ?? null,
      comments: trelloCardData.badges?.comments ?? 0,
      description: trelloCardData.badges?.description ?? false,
      due: trelloCardData.badges?.due?.at ?? null, // needs more thought
      dueComplete: trelloCardData.badges?.due?.complete || false,
      externalSource: trelloCardData.badges?.externalSource ?? null,
      lastUpdatedByAi: trelloCardData.badges?.lastUpdatedByAi ?? false,
      location: trelloCardData.badges?.location ?? false,
      start: trelloCardData.badges?.startedAt ?? null,
      subscribed: trelloCardData.badges?.viewer?.subscribed || false,
      viewingMemberVoted: trelloCardData.badges?.viewer?.voted || false,
      votes: trelloCardData.badges?.votes ?? 0,
    },
  };
};
