import type { ReadFieldFunction } from '@apollo/client/cache/core/types/common';

import { type TrelloCard } from '../generated';

export const formatTrelloLabelsData = (
  readField: ReadFieldFunction,
  trelloCardRef: Readonly<TrelloCard> | undefined,
) => {
  const labels = readField<TrelloCard['labels']>({
    fieldName: 'labels',
    args: { first: -1 },
    from: trelloCardRef,
  });
  const labelEdges = labels?.edges?.map((edge) => {
    const nodeRef = edge.node;
    const color = readField<string>('color', nodeRef);
    const name = readField<string>('name', nodeRef);
    const objectId = readField<string>('objectId', nodeRef);
    return { node: { color, name, objectId } };
  });

  return { edges: labelEdges ?? [] };
};
