import { isBool, isNumber, nullOrString } from './validateHelpers';

export type Badges = {
  attachments?: number | null;
  attachmentsByType?: {
    trello?: { board?: number | null; card?: number | null } | null;
  } | null;
  checkItems?: number | null;
  checkItemsChecked?: number | null;
  checkItemsEarliestDue?: string | null;
  comments?: number | null;
  description?: boolean | null;
  due?: { at?: string | null; complete?: boolean | null } | null;
  externalSource?: string | null;
  lastUpdatedByAi?: boolean | null;
  location?: boolean | null;
  startedAt?: string | null;
  viewer?: { subscribed?: boolean | null; voted?: boolean | null } | null;
  votes?: number | null;
};

export const validateTrelloCardBadgesData = (
  badges: Badges | undefined,
): boolean => {
  if (badges === undefined) {
    return false;
  }
  return (
    isNumber(badges.attachments) &&
    isNumber(badges.attachmentsByType?.trello?.board) &&
    isNumber(badges.attachmentsByType?.trello?.card) &&
    isNumber(badges.checkItems) &&
    isNumber(badges.checkItemsChecked) &&
    nullOrString(badges.checkItemsEarliestDue) &&
    isNumber(badges.comments) &&
    isBool(badges.description) &&
    nullOrString(badges.due?.at) &&
    isBool(badges.due?.complete) &&
    (badges.externalSource === null ||
      badges.externalSource === 'EMAIL' ||
      badges.externalSource === 'SLACK') &&
    isBool(badges.lastUpdatedByAi) &&
    isBool(badges.location) &&
    nullOrString(badges.startedAt) &&
    isBool(badges.viewer?.subscribed) &&
    isBool(badges.viewer?.voted) &&
    isNumber(badges.votes)
  );
};
