import type { ReadFieldFunction } from '@apollo/client/cache/core/types/common';

import { type TrelloCard, type TrelloSticker } from '../generated';

export const formatTrelloStickerData = (
  readField: ReadFieldFunction,
  trelloCardRef: Readonly<TrelloCard> | undefined,
) => {
  const stickers = readField<TrelloCard['stickers']>({
    fieldName: 'stickers',
    args: { first: -1 },
    from: trelloCardRef,
  });
  const stickerEdges = stickers?.edges?.map((edge) => {
    const nodeRef = edge.node;
    const objectId = readField<string>('objectId', nodeRef);
    const image = readField<string>('image', nodeRef);
    const left = readField<number>('left', nodeRef);
    const top = readField<number>('top', nodeRef);
    const rotate = readField<number>('rotate', nodeRef);
    const url = readField<string>('url', nodeRef);
    const zIndex = readField<number>('zIndex', nodeRef);

    const imageScaledRef = readField<TrelloSticker[]>('imageScaled', nodeRef);
    const imageScaled =
      imageScaledRef?.map((scaledRef) => ({
        objectId: readField<string>('objectId', scaledRef),
        height: readField<number>('height', scaledRef),
        scaled: readField<boolean>('scaled', scaledRef),
        url: readField<string>('url', scaledRef),
        width: readField<number>('width', scaledRef),
      })) ?? [];

    return {
      node: {
        objectId,
        image,
        imageScaled,
        left,
        rotate,
        top,
        url,
        zIndex,
      },
    };
  });
  return { edges: stickerEdges ?? [] };
};
