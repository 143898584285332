import type { ReadFieldFunction } from '@apollo/client/cache/core/types/common';

import type {
  TrelloAttachment,
  TrelloCardCover,
  TrelloCardCoverBrightness,
  TrelloCardCoverColor,
  TrelloCardCoverSize,
  TrelloImagePreview,
  TrelloPowerUp,
  TrelloUploadedBackground,
} from '../generated';
import type { TrelloImagePreviewNodeFields } from './mapTrelloCardDataToCard';

export const formatTrelloCardCoverData = (
  readField: ReadFieldFunction,
  cardCoverRef: Readonly<TrelloCardCover> | undefined,
) => {
  // Attachment cover
  const trelloCardCoverAttachment = readField<TrelloAttachment>(
    'attachment',
    cardCoverRef,
  );
  const attachmentId = readField<string>('objectId', trelloCardCoverAttachment);

  // Plugin cover
  const trelloCardCoverPowerUp = readField<TrelloPowerUp>(
    'powerUp',
    cardCoverRef,
  );
  const powerUpId = readField<string>('objectId', trelloCardCoverPowerUp);

  // Unsplash cover
  const trelloCardUploadedBackground = readField<TrelloUploadedBackground>(
    'uploadedBackground',
    cardCoverRef,
  );
  const uploadedBackgroundId = readField<string>(
    'objectId',
    trelloCardUploadedBackground,
  );

  const coverColor = readField<TrelloCardCoverColor>('color', cardCoverRef);
  const coverSize = readField<TrelloCardCoverSize>('size', cardCoverRef);
  const coverBrightness = readField<TrelloCardCoverBrightness>(
    'brightness',
    cardCoverRef,
  );
  const coverEdgeColor = readField<string>('edgeColor', cardCoverRef);
  const sharedSourceUrl = readField<string>('sharedSourceUrl', cardCoverRef);
  const previews = readField<TrelloCardCover['previews']>(
    'previews',
    cardCoverRef,
  );

  const previewNodeValidator = (edge: {
    node: Pick<
      TrelloImagePreview,
      'bytes' | 'height' | 'scaled' | 'url' | 'width'
    >;
  }): edge is { node: TrelloImagePreviewNodeFields } => {
    const node = edge.node;
    return (
      typeof node.bytes === 'number' &&
      typeof node.height === 'number' &&
      typeof node.scaled === 'boolean' &&
      typeof node.url === 'string' &&
      typeof node.width === 'number'
    );
  };

  return {
    attachment:
      trelloCardCoverAttachment !== undefined
        ? attachmentId
          ? {
              objectId: attachmentId,
            }
          : null
        : undefined,
    brightness: coverBrightness !== undefined ? coverBrightness : undefined,
    color: coverColor !== undefined ? coverColor : undefined,
    edgeColor: coverEdgeColor !== undefined ? coverEdgeColor : undefined,
    powerUp:
      trelloCardCoverPowerUp !== undefined
        ? powerUpId
          ? {
              objectId: powerUpId,
            }
          : null
        : undefined,
    previews:
      previews !== undefined
        ? Array.isArray(previews?.edges)
          ? {
              // Typescript wont infer the edge type correctly even though our filter function type guards
              // So lets add a type assertion here.
              edges: previews?.edges?.filter(previewNodeValidator) as Array<{
                node: TrelloImagePreviewNodeFields;
              }>,
            }
          : null
        : undefined,
    sharedSourceUrl:
      sharedSourceUrl !== undefined ? sharedSourceUrl : undefined,
    size: coverSize !== undefined ? coverSize : undefined,
    uploadedBackground:
      trelloCardUploadedBackground !== undefined
        ? uploadedBackgroundId
          ? {
              objectId: uploadedBackgroundId,
            }
          : null
        : undefined,
  };
};
