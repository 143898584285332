import * as Types from '@trello/graphql/generated';

import DocumentNode from '@trello/graphql/src/documentNode';
import * as Apollo from '@apollo/client';
import process from 'process';
export type CardFrontCombinedCardFragment = (
  { __typename: 'Card' }
  & Pick<Types.Card, 'id' | 'cardRole' | 'closed' | 'idBoard' | 'idList' | 'idMembers' | 'isTemplate' | 'mirrorSourceId' | 'name' | 'shortLink' | 'url'>
  & { badges: (
    { __typename: 'Card_Badges' }
    & Pick<Types.Card_Badges, 'attachments' | 'checkItems' | 'checkItemsChecked' | 'checkItemsEarliestDue' | 'comments' | 'description' | 'due' | 'dueComplete' | 'externalSource' | 'lastUpdatedByAi' | 'location' | 'start' | 'subscribed' | 'viewingMemberVoted' | 'votes'>
    & { attachmentsByType: (
      { __typename: 'Card_Badges_AttachmentsByType' }
      & { trello: (
        { __typename: 'Card_Badges_AttachmentsByType_Trello' }
        & Pick<Types.Card_Badges_AttachmentsByType_Trello, 'board' | 'card'>
      ) }
    ) }
  ), board: (
    { __typename: 'Board' }
    & Pick<Types.Board, 'id' | 'name' | 'url'>
    & { prefs?: Types.Maybe<(
      { __typename: 'Board_Prefs' }
      & Pick<Types.Board_Prefs, 'backgroundBrightness' | 'backgroundColor' | 'backgroundImage' | 'backgroundTopColor'>
      & { backgroundImageScaled?: Types.Maybe<Array<(
        { __typename: 'Board_Prefs_BackgroundImageScaled' }
        & Pick<Types.Board_Prefs_BackgroundImageScaled, 'height' | 'url' | 'width'>
      )>> }
    )> }
  ), cover?: Types.Maybe<(
    { __typename: 'Card_Cover' }
    & Pick<Types.Card_Cover, 'brightness' | 'color' | 'edgeColor' | 'idAttachment' | 'idPlugin' | 'idUploadedBackground' | 'sharedSourceUrl' | 'size'>
    & { scaled?: Types.Maybe<Array<(
      { __typename: 'Card_Cover_Scaled' }
      & Pick<Types.Card_Cover_Scaled, 'bytes' | 'height' | 'scaled' | 'url' | 'width'>
    )>> }
  )>, customFieldItems: Array<(
    { __typename: 'CustomFieldItem' }
    & Pick<Types.CustomFieldItem, 'id' | 'idCustomField' | 'idValue'>
    & { value?: Types.Maybe<(
      { __typename: 'CustomFieldItem_Value' }
      & Pick<Types.CustomFieldItem_Value, 'checked' | 'date' | 'number' | 'text'>
    )> }
  )>, labels: Array<(
    { __typename: 'Label' }
    & Pick<Types.Label, 'id' | 'color' | 'name'>
  )>, list: (
    { __typename: 'List' }
    & Pick<Types.List, 'name'>
  ), stickers: Array<(
    { __typename: 'Sticker' }
    & Pick<Types.Sticker, 'id' | 'image' | 'imageUrl' | 'left' | 'rotate' | 'top' | 'zIndex'>
    & { imageScaled: Array<(
      { __typename: 'Sticker_ImageScaled' }
      & Pick<Types.Sticker_ImageScaled, 'id' | 'height' | 'scaled' | 'url' | 'width'>
    )> }
  )> }
);

export const CardFrontCombinedCardFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CardFrontCombinedCard' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Card' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'badges' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'attachments' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'attachmentsByType' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'trello' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'board' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'card' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'checkItems' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'checkItemsChecked' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'checkItemsEarliestDue' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'comments' } },
                { kind: 'Field', name: { kind: 'Name', value: 'description' } },
                { kind: 'Field', name: { kind: 'Name', value: 'due' } },
                { kind: 'Field', name: { kind: 'Name', value: 'dueComplete' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'externalSource' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'lastUpdatedByAi' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'location' } },
                { kind: 'Field', name: { kind: 'Name', value: 'start' } },
                { kind: 'Field', name: { kind: 'Name', value: 'subscribed' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'viewingMemberVoted' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'votes' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'board' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'prefs' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'backgroundBrightness' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'backgroundColor' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'backgroundImage' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'backgroundImageScaled' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'height' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'url' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'width' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'backgroundTopColor' },
                      },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'url' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'cardRole' } },
          { kind: 'Field', name: { kind: 'Name', value: 'closed' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'cover' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'brightness' } },
                { kind: 'Field', name: { kind: 'Name', value: 'color' } },
                { kind: 'Field', name: { kind: 'Name', value: 'edgeColor' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'idAttachment' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'idPlugin' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'idUploadedBackground' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'scaled' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'bytes' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'height' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'scaled' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'url' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'width' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'sharedSourceUrl' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'size' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'customFieldItems' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'idCustomField' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'idValue' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'value' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'checked' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'date' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'number' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'text' } },
                    ],
                  },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'idBoard' } },
          { kind: 'Field', name: { kind: 'Name', value: 'idList' } },
          { kind: 'Field', name: { kind: 'Name', value: 'idMembers' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isTemplate' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'labels' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'color' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'list' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'mirrorSourceId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'shortLink' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'stickers' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'image' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'imageScaled' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'height' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'scaled' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'url' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'width' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'imageUrl' } },
                { kind: 'Field', name: { kind: 'Name', value: 'left' } },
                { kind: 'Field', name: { kind: 'Name', value: 'rotate' } },
                { kind: 'Field', name: { kind: 'Name', value: 'top' } },
                { kind: 'Field', name: { kind: 'Name', value: 'zIndex' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'url' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode;

interface UseCardFrontCombinedCardFragmentOptions
  extends Omit<
    Apollo.UseFragmentOptions<
      CardFrontCombinedCardFragment,
      Apollo.OperationVariables
    >,
    'fragment' | 'fragmentName' | 'from'
  > {
  from: Apollo.StoreObject | Apollo.Reference;
  returnPartialData?: boolean;
}

interface UseCardFrontCombinedCardFragmentResult
  extends Omit<
    Apollo.UseFragmentResult<CardFrontCombinedCardFragment>,
    'data'
  > {
  data?: CardFrontCombinedCardFragment;
}

export const useCardFrontCombinedCardFragment = ({
  from,
  returnPartialData,
  ...options
}: UseCardFrontCombinedCardFragmentOptions): UseCardFrontCombinedCardFragmentResult => {
  const result = Apollo.useFragment<CardFrontCombinedCardFragment>({
    ...options,
    fragment: CardFrontCombinedCardFragmentDoc,
    fragmentName: 'CardFrontCombinedCard',
    from: {
      __typename: 'Card',
      ...from,
    },
  });

  // Ensure that the fragment result is not typed as a DeepPartial.
  if (!result.complete && !returnPartialData) {
    if (process.env.NODE_ENV === 'development') {
      console.warn('Fragment data is incomplete.', result);
    }
    return {
      ...result,
      data: undefined,
    };
  }

  return { ...result, data: result.data as CardFrontCombinedCardFragment };
};
