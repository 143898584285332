import type { ReadFieldFunction } from '@apollo/client/cache/core/types/common';

import type {
  TrelloBoard,
  TrelloBoardBackground,
  TrelloBoardPrefs,
  TrelloEnterprise,
  TrelloWorkspace,
} from '../generated';

export const formatTrelloBoardData = (
  readField: ReadFieldFunction,
  boardRef: Readonly<TrelloBoard> | undefined,
  boardId: string,
) => {
  const trelloCardWorkspaceRef = readField<TrelloWorkspace>(
    'workspace',
    boardRef,
  );
  const trelloCardEnterpriseRef = readField<TrelloEnterprise>(
    'enterprise',
    boardRef,
  );
  const workspaceId = readField<string>('objectId', trelloCardWorkspaceRef);
  const enterpriseId = readField<string>('objectId', trelloCardEnterpriseRef);
  const name = readField<string>('name', boardRef);
  const url = readField<string>('url', boardRef);
  const boardPrefsRef = readField<TrelloBoardPrefs>('prefs', boardRef);
  const backgroundRef = readField<TrelloBoardBackground>(
    'background',
    boardPrefsRef,
  );
  const brightness = readField<string>('brightness', backgroundRef);
  const color = readField<string>('color', backgroundRef);
  const image = readField<string>('image', backgroundRef);
  const imageScaledRef = readField('imageScaled', backgroundRef);
  const topColor = readField<string>('topColor', backgroundRef);

  const imageScaled = Array.isArray(imageScaledRef)
    ? imageScaledRef.map((scaledImage) => ({
        height: scaledImage.height,
        url: scaledImage.url,
        width: scaledImage.width,
      }))
    : null;

  return {
    objectId: boardId,
    name,
    url,
    prefs:
      boardPrefsRef !== undefined
        ? {
            background: {
              brightness,
              color,
              image,
              imageScaled,
              topColor,
            },
          }
        : undefined,
    workspace:
      trelloCardWorkspaceRef !== undefined
        ? workspaceId
          ? { objectId: workspaceId }
          : null
        : undefined,
    enterprise:
      trelloCardEnterpriseRef !== undefined
        ? enterpriseId
          ? { objectId: enterpriseId }
          : null
        : undefined,
  };
};
