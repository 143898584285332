import type {
  CardRole,
  CustomField_Type,
  Label_Color,
  TrelloBoard,
  TrelloBoardBackground,
  TrelloCard,
  TrelloCardAttachmentsCount,
  TrelloCardBadgeDueInfo,
  TrelloCardBadges,
  TrelloCardCover,
  TrelloCardViewer,
  TrelloCustomField,
  TrelloCustomFieldDisplay,
  TrelloCustomFieldItem,
  TrelloCustomFieldItemValueInfo,
  TrelloCustomFieldOption,
  TrelloCustomFieldOptionValue,
  TrelloImagePreview,
  TrelloLabel,
  TrelloMember,
  TrelloMemberNonPublicData,
  TrelloScaleProps,
  TrelloSticker,
} from '../generated';
import { mapTrelloCardBadgeDataToCardBadge } from './mapTrelloCardBadgesDataToCardBadges';
import { mapTrelloCardCoverDataToCardCover } from './mapTrelloCardCoverDataToCardCover';
import {
  mapTrelloCardCustomFieldItems,
  mapTrelloCardCustomFieldItemsToCustomFields,
} from './mapTrelloCardCustomFieldItems';
import { mapTrelloCardMemberIdsToIdMembers } from './mapTrelloCardMemberIdsToIdMembers';

type TrelloCardCoverAttachmentFields = Pick<
  NonNullable<TrelloCardCover['attachment']>,
  'objectId'
>;
type TrelloCardCoverPowerUpFields = Pick<
  NonNullable<TrelloCardCover['powerUp']>,
  'objectId'
>;
type TrelloCardCoverUploadedBackgroundFields = Pick<
  NonNullable<TrelloCardCover['uploadedBackground']>,
  'objectId'
>;

export type TrelloImagePreviewNodeFields = {
  bytes: NonNullable<TrelloImagePreview['bytes']>;
  height: NonNullable<TrelloImagePreview['height']>;
  scaled: NonNullable<TrelloImagePreview['scaled']>;
  url: NonNullable<TrelloImagePreview['url']>;
  width: NonNullable<TrelloImagePreview['width']>;
};

export type TrelloCardCoverFields = Exclude<
  Pick<
    TrelloCardCover,
    'brightness' | 'color' | 'edgeColor' | 'sharedSourceUrl' | 'size'
  >,
  undefined
> & {
  attachment: TrelloCardCoverAttachmentFields | null;
  powerUp: TrelloCardCoverPowerUpFields | null;
  uploadedBackground: TrelloCardCoverUploadedBackgroundFields | null;
  previews: { edges: Array<{ node: TrelloImagePreviewNodeFields }> } | null;
};

export type TrelloStickerImageScaledFields = {
  objectId: TrelloImagePreview['objectId'];
  height: NonNullable<TrelloImagePreview['height']>;
  scaled: TrelloImagePreview['scaled'];
  url: NonNullable<TrelloImagePreview['url']>;
  width: NonNullable<TrelloImagePreview['width']>;
};

type TrelloStickerFields = {
  objectId: NonNullable<TrelloSticker['objectId']>;
  image: NonNullable<TrelloSticker['image']>;
  imageScaled: NonNullable<TrelloStickerImageScaledFields>[];
  left: NonNullable<TrelloSticker['left']>;
  rotate: NonNullable<TrelloSticker['rotate']>;
  top: NonNullable<TrelloSticker['top']>;
  url: NonNullable<TrelloSticker['url']>;
  zIndex: NonNullable<TrelloSticker['zIndex']>;
};

type TrelloLabelFields = {
  edges: Array<{
    node: {
      color: NonNullable<TrelloLabel['color']>;
      name: NonNullable<TrelloLabel['name']>;
      objectId: NonNullable<TrelloLabel['objectId']>;
    };
  }>;
};

type TrelloListFields = {
  name: NonNullable<NonNullable<TrelloCard['list']>['name']>;
  objectId: NonNullable<NonNullable<TrelloCard['list']>['objectId']>;
  board: TrelloBoardFields;
};

type TrelloCardAttachmentsByTypeField = {
  trello: {
    board: Exclude<TrelloCardAttachmentsCount['board'], undefined>;
    card: Exclude<TrelloCardAttachmentsCount['card'], undefined>;
  } | null;
};

type TrelloCardDueBadgeField = {
  at: Exclude<TrelloCardBadgeDueInfo['at'], undefined>;
  complete: Exclude<TrelloCardBadgeDueInfo['complete'], undefined>;
};

type TrelloCardViewerBadgeField = {
  subscribed: Exclude<TrelloCardViewer['subscribed'], undefined>;
  voted: Exclude<TrelloCardViewer['voted'], undefined>;
};

export type TrelloCardBadgeFields = Exclude<
  Pick<
    TrelloCardBadges,
    | 'attachments'
    | 'checkItems'
    | 'checkItemsChecked'
    | 'checkItemsEarliestDue'
    | 'comments'
    | 'description'
    | 'externalSource'
    | 'lastUpdatedByAi'
    | 'location'
    | 'startedAt'
    | 'votes'
  >,
  undefined
> & {
  attachmentsByType: TrelloCardAttachmentsByTypeField;
  due: TrelloCardDueBadgeField;
  viewer: TrelloCardViewerBadgeField;
};

type TrelloMemberNodeFields = Pick<
  TrelloMember,
  'avatarUrl' | 'fullName' | 'initials' | 'username'
> & {
  objectId: NonNullable<TrelloMember['objectId']>;
  nonPublicData: Pick<
    TrelloMemberNonPublicData,
    'avatarUrl' | 'fullName' | 'initials'
  >;
};

type TrelloCustomFieldOptionValueFields = {
  text: NonNullable<TrelloCustomFieldOptionValue['text']>;
};
type TrelloCustomFieldOptionFields = {
  color: NonNullable<TrelloCustomFieldOption['color']>;
  objectId: NonNullable<TrelloCustomFieldOption['objectId']>;
  position: NonNullable<TrelloCustomFieldOption['position']>;
  value: TrelloCustomFieldOptionValueFields;
};

type TrelloCustomFieldFields = {
  display: { cardFront: NonNullable<TrelloCustomFieldDisplay['cardFront']> };
  name: NonNullable<TrelloCustomField['name']>;
  objectId: NonNullable<TrelloCustomField['objectId']>;
  options: TrelloCustomFieldOptionFields[];
  position: NonNullable<TrelloCustomField['position']>;
  type: CustomField_Type;
};

type TrelloCustomFieldItemValueFields = {
  checked: Exclude<TrelloCustomFieldItemValueInfo['checked'], undefined>;
  date: Exclude<TrelloCustomFieldItemValueInfo['date'], undefined>;
  id: Exclude<TrelloCustomFieldItemValueInfo['id'], undefined>;
  number: Exclude<TrelloCustomFieldItemValueInfo['number'], undefined>;
  text: Exclude<TrelloCustomFieldItemValueInfo['text'], undefined>;
};

type TrelloCustomFieldItemFields = {
  edges: Array<{
    node: {
      customField: TrelloCustomFieldFields;
      model: { objectId: NonNullable<TrelloCard['objectId']> };
      objectId: NonNullable<TrelloCustomFieldItem['objectId']>;
      value: TrelloCustomFieldItemValueFields;
    };
  }>;
};

type TrelloCardBoardFields = {
  name: NonNullable<TrelloBoard['name']>;
  objectId: NonNullable<TrelloBoard['objectId']>;
  url: NonNullable<TrelloBoard['url']>;
  prefs: {
    background: TrelloBoardBackgroundPrefsFields;
  };
};

export type TrelloCardFields = Pick<
  NonNullable<TrelloCard>,
  'mirrorSourceId' | 'name' | 'objectId'
> &
  Pick<TrelloCard, 'role'> & {
    badges: TrelloCardBadgeFields;
    board: TrelloCardBoardFields;
    closed: NonNullable<TrelloCard['closed']>;
    cover: TrelloCardCoverFields | null;
    customFieldItems: TrelloCustomFieldItemFields;
    isTemplate: NonNullable<TrelloCard['isTemplate']>;
    labels: TrelloLabelFields;
    list: TrelloListFields;
    name: NonNullable<TrelloCard['name']>;
    members: {
      edges: Array<{
        node: TrelloMemberNodeFields;
      }>;
    };
    shortLink: NonNullable<TrelloCard['shortLink']>;
    stickers: { edges: Array<{ node: TrelloStickerFields }> };
    url: NonNullable<TrelloCard['url']>;
  };

type TrelloBoardFields = {
  objectId: NonNullable<TrelloBoard['objectId']>;
};

type TrelloBoardImageScaledFields = {
  height: NonNullable<TrelloScaleProps['height']>;
  url: NonNullable<TrelloScaleProps['url']>;
  width: NonNullable<TrelloScaleProps['width']>;
};

type TrelloBoardBackgroundPrefsFields = {
  color: TrelloBoardBackground['color'];
  image: TrelloBoardBackground['image'];
  brightness: 'dark' | 'light' | 'unknown';
  topColor: NonNullable<TrelloBoardBackground['topColor']>;
  imageScaled: TrelloBoardImageScaledFields[] | null;
};

type TrelloBoardEnterpriseFields = Pick<
  NonNullable<TrelloBoard['enterprise']>,
  'objectId'
>;
type TrelloWorkspaceFields = Pick<
  NonNullable<TrelloBoard['enterprise']>,
  'objectId'
>;

export type TrelloBoardAll = TrelloBoardFields & {
  enterprise: TrelloBoardEnterpriseFields | null;
  workspace: TrelloWorkspaceFields | null;
};

/**
Function that takes in card data from the native GraphQL TrelloCard
and transforms it into client-side Card fields to hydrate the cache
for rendering cards on planner
 */
export function mapTrelloCardDataToCard(trelloCardData: TrelloCardFields) {
  const badgeFields = mapTrelloCardBadgeDataToCardBadge(trelloCardData);
  const coverFields = mapTrelloCardCoverDataToCardCover(trelloCardData);
  const customFieldItems = mapTrelloCardCustomFieldItems(trelloCardData);
  const members = mapTrelloCardMemberIdsToIdMembers(trelloCardData);

  return {
    id: trelloCardData.objectId,
    ...badgeFields,
    board: {
      __typename: 'Board' as const,
      id: trelloCardData.board.objectId,
      name: trelloCardData.board.name,
      prefs: {
        __typename: 'Board_Prefs' as const,
        backgroundBrightness: trelloCardData.board.prefs.background.brightness,
        backgroundImage: trelloCardData.board.prefs.background.image ?? null,
        backgroundColor: trelloCardData.board.prefs.background.color ?? null,
        backgroundImageScaled: trelloCardData.board.prefs.background.imageScaled
          ? trelloCardData.board.prefs.background.imageScaled.map(
              (scaledImage) => ({
                __typename: 'Board_Prefs_BackgroundImageScaled' as const,
                height: scaledImage.height,
                url: scaledImage.url,
                width: scaledImage.width,
              }),
            )
          : null,
        backgroundTopColor:
          trelloCardData.board.prefs.background.topColor ?? null,
      },
      url: trelloCardData.board.url,
    },
    closed: trelloCardData.closed,
    ...coverFields,
    customFieldItems,
    idList: trelloCardData.list?.objectId,
    idBoard: trelloCardData.list?.board.objectId,
    isTemplate: trelloCardData.isTemplate ?? false,
    labels: trelloCardData.labels.edges.map((edge) => {
      const node = edge.node;
      return {
        __typename: 'Label' as const,
        id: node.objectId,
        color: node.color as Label_Color,
        name: node.name,
      };
    }),
    list: {
      __typename: 'List' as const,
      id: trelloCardData.list.objectId,
      name: trelloCardData.list.name,
    },
    mirrorSourceId: trelloCardData.mirrorSourceId,
    idMembers: members,
    name: trelloCardData.name,
    cardRole: (trelloCardData.role?.toLowerCase() as CardRole) ?? null,
    shortLink: trelloCardData.shortLink,
    stickers: trelloCardData.stickers.edges.map((edge) => {
      const node = edge.node;
      return {
        __typename: 'Sticker' as const,
        id: node.objectId,
        image: node.image,
        imageScaled:
          node.imageScaled?.map((scaled) => ({
            __typename: 'Sticker_ImageScaled' as const,
            id: scaled.objectId ?? null,
            height: scaled?.height,
            scaled: scaled?.scaled,
            url: scaled?.url,
            width: scaled?.width,
          })) ?? [],
        left: node.left,
        rotate: node.rotate,
        top: node.top,
        imageUrl: node.url,
        zIndex: node.zIndex,
      };
    }),
    url: trelloCardData.url,
  };
}

/**
Function that takes in board data from the native GraphQL TrelloBoard
and transforms it into client-side Board fields to hydrate the cache
for rendering cards on planner
 */
export function mapTrelloBoardDataToBoard(
  trelloBoardData: TrelloBoardAll,
  trelloCardData: TrelloCardFields,
) {
  const customFields =
    mapTrelloCardCustomFieldItemsToCustomFields(trelloCardData);

  return {
    id: trelloBoardData.objectId,
    idEnterprise: trelloBoardData.enterprise?.objectId ?? null,
    idOrganization: trelloBoardData.workspace?.objectId ?? null,
    customFields,
  };
}
