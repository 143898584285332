// Ensure that keys are sorted, to reduce merge conflicts
/* eslint sort-keys: "error" */
type PrimitiveSupportedGateTypes = boolean | number | string;
export type SupportedGateTypes =
  | Array<PrimitiveSupportedGateTypes>
  | PrimitiveSupportedGateTypes
  | {
      [key: string]: PrimitiveSupportedGateTypes;
    };

/**
 * Feature gates / experiments in Statsig have to be defined here as well.
 * The `owner` field should be your Trello username.
 */
export const featureGates = {
  'ah-trello-web-shadow-it-controls': {
    createdOn: '2024-08-19',
    owner: 'soluwaleimu',
  },
  billplat_ccp_create_workspace: {
    createdOn: '2024-07-09',
    owner: 'jborczuk',
  },
  billplat_m2_workspace_pages: {
    createdOn: '2024-11-27',
    owner: 'pperry',
  },
  billplat_modernize_members_profile_page: {
    createdOn: '2024-12-02',
    owner: 'tsiddiqui',
  },
  billplat_tanzania_vat: {
    createdOn: '2025-01-22',
    owner: 'aterkik',
  },
  ecosystem_power_up_views: {
    createdOn: '2024-10-02',
    owner: 'jlo',
  },
  elements_unset_duplicate_dsc_cookie: {
    createdOn: '2024-10-09',
    owner: 'rwang3',
  },
  enterprise_email_verification_refresher: {
    createdOn: '2025-01-24',
    owner: 'dbernal',
  },
  enterprise_link_to_atlassian_org_v2_web: {
    createdOn: '2024-09-13',
    owner: 'mkalil',
  },
  ghost_mirror_card_back_subscription: {
    createdOn: '2025-01-14',
    owner: 'jsaussy',
  },
  ghost_use_mirror_card_subscriptions: {
    createdOn: '2024-12-16',
    owner: 'jsaussy',
  },
  goo_board_pup_menu_search: {
    createdOn: '2024-01-24',
    owner: 'evgeniyagorobets1',
  },
  goo_graphql_connectivity_flags: {
    createdOn: '2024-10-17',
    owner: 'ccurtis',
  },
  goo_slower_client_reconnects: {
    createdOn: '2024-10-21',
    owner: 'ccurtis',
  },
  gql_client_subscriptions: {
    createdOn: '2024-10-28',
    owner: 'egorobets',
  },
  gqldata_email_attachment_preview: {
    createdOn: '2024-09-12',
    owner: 'cgarrison',
  },
  gqldata_inbox_email_ai: {
    createdOn: '2024-10-18',
    owner: 'cgarrison',
  },
  legacy_url_error_page: {
    createdOn: '2024-09-10',
    owner: 'mkalil',
  },
  phx_board_menu_modernization_m2: {
    createdOn: '2024-10-01',
    owner: 'hburinda',
  },
  phx_board_tile_redesign: {
    createdOn: '2025-01-10',
    owner: 'cma2',
  },
  phx_card_back_feedback: {
    createdOn: '2025-02-14',
    owner: 'mellis',
  },
  phx_card_back_redesign: {
    createdOn: '2025-01-06',
    owner: 'mellis',
  },
  phx_composable_editor: {
    createdOn: '2024-11-15',
    owner: 'mellis',
  },
  phx_done_state_fast_follows: {
    createdOn: '2024-02-13',
    owner: 'ajaiman',
  },
  phx_editor_dates: {
    createdOn: '2024-09-27',
    owner: 'mellis',
  },
  phx_generic_action_on_card_back: {
    createdOn: '2024-10-16',
    owner: 'dstraus',
  },
  phx_inbox_assistant: {
    createdOn: '2024-09-20',
    owner: 'ajaiman',
  },
  phx_new_card_done_state: {
    createdOn: '2024-11-15',
    owner: 'smccumsey',
  },
  phx_new_card_done_state_workspace: {
    createdOn: '2024-12-16',
    owner: 'ajaiman',
  },
  phx_personal_productivity_panel_resize: {
    createdOn: '2025-01-27',
    owner: 'hburinda',
  },
  phx_remove_giphy: {
    createdOn: '2024-12-12',
    owner: 'mramosmartins',
  },
  tplat_fg_migrate_renderapp: {
    createdOn: '2024-08-22',
    owner: 'koduche',
  },
  tplat_native_graphql_migration_milestone_2: {
    createdOn: '2024-09-11',
    owner: 'koduche',
  },
  'trello-server-e2b-ai': {
    createdOn: '2024-05-14',
    owner: 'brianzawisza1',
  },
  'trello-web-seat-automation-member-blocklist': {
    createdOn: '2024-08-08',
    owner: 'alitskevitch',
  },
  trello_enterprise_inbox_board_redirect: {
    createdOn: '2025-01-14',
    owner: 'jlei',
  },
  trello_enterprise_inbox_custom_background: {
    createdOn: '2025-01-24',
    owner: 'michaelnwani5',
  },
  trello_experiment_primary_identifier_enforcement: {
    createdOn: '2024-11-22',
    owner: 'mpittman',
  },
  trello_hover_inbetween_cards: {
    createdOn: '2024-09-27',
    owner: 'greznicov',
  },
  trello_native_graphql_migration_milestone_1: {
    createdOn: '2024-07-12',
    owner: 'koduche',
  },
  'trello_non-ppm_single_player': {
    createdOn: '2024-01-22',
    owner: 'achung',
  },
  trello_paid_workspaces: {
    createdOn: '2024-03-01',
    owner: 'wloo2',
  },
  trello_personal_productivity_release: {
    createdOn: '2024-11-13',
    owner: 'sronderos',
  },
  trello_pp_beta_box: {
    createdOn: '2024-12-19',
    owner: 'greznicov',
  },
  trello_pp_opt_in_banner: {
    createdOn: '2024-12-17',
    owner: 'greznicov',
  },
  trello_pp_opt_in_modal: {
    createdOn: '2025-01-29',
    owner: 'bessary',
  },
  trello_reverse_trials: {
    createdOn: '2024-10-07',
    owner: 'greznicov',
  },
  trello_xf_discovery_ads_control: {
    createdOn: '2024-08-02',
    owner: 'achung',
  },
  trello_xf_experiment_analytics_toggle: {
    createdOn: '2024-03-11',
    owner: 'achung',
  },
  trello_xf_free_ppm_users_experience: {
    createdOn: '2024-09-24',
    owner: 'achung',
  },
  trello_xf_invite_experience_2: {
    createdOn: '2024-05-14',
    owner: 'mleaf',
  },
  trello_xf_non_ppm_multi_player_experience: {
    createdOn: '2024-09-24',
    owner: 'achung',
  },
  trello_xf_non_ppm_single_player_experience: {
    createdOn: '2024-09-24',
    owner: 'achung',
  },
  trello_xf_one_liner_header_experience: {
    createdOn: '2024-05-10',
    owner: 'mshih',
  },
  trello_xf_paid_users_experience: {
    createdOn: '2024-09-24',
    owner: 'achung',
  },
  trello_xf_post_office_board_screen_placement: {
    createdOn: '2024-10-24',
    owner: 'gbolloch',
  },
  trello_xf_use_card_drag_external: {
    createdOn: '2024-05-08',
    owner: 'jsaussy',
  },
  trello_xf_use_server_list_sorting: {
    createdOn: '2024-06-05',
    owner: 'ccurtis',
  },
  trello_xf_use_view_for_calendar_powerup: {
    createdOn: '2024-04-23',
    owner: 'jsaussy',
  },
  xf_fg_planner: {
    createdOn: '2024-08-29',
    owner: 'rwang3',
  },
  xf_inbox_spike: {
    createdOn: '2024-06-03',
    owner: 'afecenko',
  },
  xf_list_sort_modernization: {
    createdOn: '2024-07-15',
    owner: 'julietzhang',
  },
  xf_mirror_card_spotlight: {
    createdOn: '2024-11-05',
    owner: 'dvenkatachalam',
  },
  xf_mirror_cards: {
    createdOn: '2024-07-24',
    owner: 'slondon',
  },
  xf_planner_m2: {
    createdOn: '2024-11-06',
    owner: 'acrawford',
  },
  xf_smart_lists_new_trait: {
    createdOn: '2025-02-11',
    owner: 'bessary',
  },
  xf_splitscreen_onboarding_video: {
    createdOn: '2025-01-08',
    owner: 'dvenkatachalam',
  },
  xf_statsig_feature_gates_refresher: {
    createdOn: '2024-10-10',
    owner: 'achung',
  },
} as const;

export const featureExperiments = {
  'new_user_onboarding_flow_and_in-product_discovery': {
    createdOn: '2025-01-31',
    owner: 'achung',
    parameters: {
      campaign: ['moonshot', 'newUserSplitScreenOnboarding'],
    },
    primaryIdentifier: 'atlassianAccountId',
  },
  phx_trello_home_experiment: {
    createdOn: '2025-01-28',
    owner: 'mramosmartins',
    parameters: {
      cohort: ['experiment', 'control'],
    },
    primaryIdentifier: 'atlassianAccountId',
  },
  trello_collapse_list_upsell_upgrade_multivariate: {
    createdOn: '2024-08-01',
    owner: 'kbarrett',
    parameters: {
      cohort: ['variantA', 'variantB', 'variantC'],
    },
    primaryIdentifier: 'atlassianAccountId',
  },
  trello_enterprise_trial_ended_friction: {
    createdOn: '2024-07-16',
    owner: 'jlei',
    parameters: {
      cohort: ['experiment', 'control'],
      // eslint-disable-next-line @trello/no-module-logic
      experimentStartDate: [new Date('2024-08-14T11:30:30').toISOString()],
    },
    primaryIdentifier: 'trelloWorkspaceId',
  },
  trello_phoenix_collapse_list_free_trial_baseline: {
    createdOn: '2024-08-08',
    owner: 'kbarrett',
    parameters: {
      cohort: ['experiment', 'control'],
    },
    primaryIdentifier: 'atlassianAccountId',
  },
  trello_phoenix_collapse_list_free_trial_multi: {
    createdOn: '2024-08-20',
    owner: 'kbarrett',
    parameters: {
      cohort: ['variantA', 'variantB', 'variantC'],
    },
    primaryIdentifier: 'atlassianAccountId',
  },
  trello_phoenix_collapse_list_upgrade_baseline: {
    createdOn: '2024-07-22',
    owner: 'kbarrett',
    parameters: {
      cohort: ['experiment', 'control'],
    },
    primaryIdentifier: 'atlassianAccountId',
  },
  xf_boards_home_sidebar_placement: {
    createdOn: '2025-01-13',
    owner: 'gbolloch',
    parameters: {
      cohort: ['control', 'experiment'],
    },
    primaryIdentifier: 'atlassianAccountId',
  },
  xf_de_facto_bandits: {
    createdOn: '2024-10-05',
    owner: 'gbolloch',
    parameters: {
      cohort: ['experiment', 'control'],
    },
    primaryIdentifier: 'atlassianAccountId',
  },
  xf_enterprise_self_serve: {
    createdOn: '2024-09-10',
    owner: 'dvenkatachalam',
    parameters: {
      cohort: ['control', 'experiment'],
    },
    primaryIdentifier: 'atlassianAccountId',
  },
} as const;

// When adding a new layer, change the object type as shown below
// export const featureLayers = {} as const;
export const featureLayers: Record<string, { parameters: object }> = {};

export type FeatureGateKeys = keyof typeof featureGates;
export type FeatureExperimentKeys = keyof typeof featureExperiments;
export type FeatureLayersKeys = keyof typeof featureLayers;
export type FeatureExperimentParameters<K extends FeatureExperimentKeys> =
  keyof (typeof featureExperiments)[K]['parameters'];
export type FeatureExperimentPrimaryIdentifiers =
  | 'analyticsAnonymousId'
  | 'atlassianAccountId'
  | 'trelloUserId'
  | 'trelloWorkspaceId';
export type FeatureLayerParameters<K extends FeatureLayersKeys> =
  keyof (typeof featureLayers)[K]['parameters'];

export type RegisteredFeatureKey = FeatureExperimentKeys | FeatureGateKeys;

/**
 * Used to narrow down to the experiment variations types, given the experiment key and parameter key
 * e.g. type Variations = ExperimentVariations<'trello_experiment_key', 'cohort'>
 */
export type ExperimentVariations<
  K extends FeatureExperimentKeys,
  P extends FeatureExperimentParameters<K>,
> =
  // Explicit any type is used where we don't actually care about the type. It is used just so we can then
  // index on the variation tuples using [number].
  | 'not-enrolled'
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  | ((typeof featureExperiments)[K]['parameters'] extends Record<P, any>
      ? (typeof featureExperiments)[K]['parameters'][P][number]
      : never);

/**
 * Used to narrow down to the experiment variations types, given the layer key and parameter key
 * e.g. type Variations = ExperimentVariations<'trello_layer_key', 'cohort'>
 */
export type LayerVariations<
  K extends FeatureLayersKeys,
  P extends FeatureLayerParameters<K>,
> =
  // Explicit any type is used where we don't actually care about the type. It is used just so we can then
  // index on the variation tuples using [number].
  | 'not-enrolled'
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  | ((typeof featureLayers)[K]['parameters'] extends Record<P, any>
      ? (typeof featureLayers)[K]['parameters'][P][number]
      : never);
