import type { TrelloBoardAll } from './mapTrelloCardDataToCard';
import { isString, nullOrString } from './validateHelpers';

export const validateTrelloBoardData = (inputBoard: {
  objectId?: string | null;
  workspace?: {
    objectId?: string | null;
  } | null;
  enterprise?: { objectId?: string | null } | null;
}): inputBoard is TrelloBoardAll => {
  const { objectId, workspace, enterprise } = inputBoard;
  return (
    nullOrString(objectId) &&
    (workspace === null || isString(workspace?.objectId)) &&
    (enterprise === null || isString(enterprise?.objectId))
  );
};
