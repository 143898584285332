import type { StoreObject, TypePolicies } from '@apollo/client';
import type { ReadFieldFunction } from '@apollo/client/cache/core/types/common';
import { relayStylePagination, type Reference } from '@apollo/client/utilities';
import { gql } from 'graphql-tag';
import { v4 as uuidv4 } from 'uuid';

import { idToDate } from '@trello/dates';
import { idCache } from '@trello/id-cache';

import { addParentConnection } from './apolloCache/addParentConnection';
import { defaultKeyArgsFunction } from './apolloCache/defaultKeyArgsFunction';
import { mergeIncomingAndFillNulls } from './apolloCache/mergeIncomingAndFillNulls';
import { readWithDefault } from './apolloCache/readWithDefault';
import {
  boardToCardsRelation,
  listToCardsRelation,
} from './apolloCache/relation';
import { saveParentId } from './apolloCache/saveParentId';
import {
  CardFrontBoardCustomFieldsFragmentDoc,
  type CardFrontBoardCustomFieldsFragment,
} from './fragments/CardFrontBoardCustomFieldsFragment.generated';
import {
  CardFrontBoardFragmentDoc,
  type CardFrontBoardFragment,
} from './fragments/CardFrontBoardFragment.generated';
import {
  CardFrontCombinedCardFragmentDoc,
  type CardFrontCombinedCardFragment,
} from './fragments/CardFrontCombinedCardFragment.generated';
import {
  CardFrontMemberAvatarFragmentDoc,
  type CardFrontMemberAvatarFragment,
} from './fragments/CardFrontMemberAvatarFragment.generated';
import { formatTrelloBoardData } from './plannerCardDataMapping/formatTrelloBoardData';
import { formatTrelloCardBadgesData } from './plannerCardDataMapping/formatTrelloCardBadgesData';
import { formatTrelloCardCoverData } from './plannerCardDataMapping/formatTrelloCardCoverData';
import { formatTrelloCardCustomFieldItemsData } from './plannerCardDataMapping/formatTrelloCardCustomFieldItemsData';
import { formatTrelloCardMemberData } from './plannerCardDataMapping/formatTrelloCardMemberData';
import { formatTrelloLabelsData } from './plannerCardDataMapping/formatTrelloLabelsData';
import { formatTrelloListData } from './plannerCardDataMapping/formatTrelloListData';
import { formatTrelloStickerData } from './plannerCardDataMapping/formatTrelloStickerData';
import {
  mapTrelloBoardDataToBoard,
  mapTrelloCardDataToCard,
} from './plannerCardDataMapping/mapTrelloCardDataToCard';
import { mapTrelloMemberDataToMember } from './plannerCardDataMapping/mapTrelloMemberDataToMember';
import { validateTrelloBoardData } from './plannerCardDataMapping/validateTrelloBoardData';
import { validateTrelloCardData } from './plannerCardDataMapping/validateTrelloCardData';
import {
  batchRestResourceFieldPolicies,
  readMemberMe,
  restResourceFieldPolicies,
} from './restResourceResolver/restResourceCacheRedirects';
import type {
  Organization_Limits,
  OrganizationCardsArgs,
  TrelloBoard,
  TrelloCard,
  TrelloCardBadges,
  TrelloCardCover,
  TrelloCardRole,
  TrelloCustomFieldItemConnection,
  TrelloList,
  TrelloPlannerCalendarEventCard,
} from './generated';
import { mergeArrays } from './mergeArrays';
import { queryMap } from './resolvers';

type Edge = { node: Reference };

const addMemberToIdCache = (
  member: Reference,
  readField: ReadFieldFunction,
) => {
  const typename = member && readField('__typename', member);

  const id = readField('id', member) as string;
  const objectId = readField('objectId', member) as string;
  const nodeId = readField('nodeId', member) as string;
  const username = readField('username', member) as string;

  const ari = typename === 'TrelloMember' ? id : nodeId;
  const trelloId = typename === 'TrelloMember' ? objectId : id;

  if (trelloId && username && !idCache.getMemberId(username)) {
    idCache.setMemberId(username, trelloId);
  }
  if (ari && username && !idCache.getMemberAri(username)) {
    idCache.setMemberAri(username, ari);
  }
};

const fieldPolicies = {
  // eslint-disable-next-line @trello/no-module-logic
  ...restResourceFieldPolicies(queryMap),
  // eslint-disable-next-line @trello/no-module-logic
  ...batchRestResourceFieldPolicies(queryMap),
};

export const typePolicies: TypePolicies = {
  Query: {
    fields: {
      ...fieldPolicies,
      member: {
        read: readMemberMe,
      },
      domain: {
        merge: true,
      },
      trello: {
        merge: true,
      },
      organizationBoards: {
        keyArgs: ['id', 'filter', 'search', 'sortBy', 'sortOrder', 'tags'],
        // If fetching more, append new results to the existing results
        // Otherwise, replace the existing data with the incoming results
        merge(existing = [], incoming = [], opts) {
          return opts?.args?.offset
            ? mergeArrays(existing, incoming)
            : incoming;
        },
      },
      memberActions: {
        keyArgs: ['memberId', 'limit'],
        merge(existing = [], incoming: Reference[]) {
          const combined = mergeArrays(existing, incoming);
          const sorted = combined.sort((a, b) => {
            const idA = a.__ref.replace('Action:', '');
            const idB = b.__ref.replace('Action:', '');
            return idToDate(idB).getTime() - idToDate(idA).getTime();
          });
          return sorted;
        },
      },
    },
  },
  Action: {
    fields: {
      reactions: {
        // eslint-disable-next-line @trello/no-module-logic
        read: readWithDefault([]),
      },
    },
  },
  Board: {
    fields: {
      actions: {
        merge(existing = [], incoming: Reference[]) {
          const combined = mergeArrays(existing, incoming);
          const sorted = combined.sort((a, b) => {
            const idA = a.__ref.replace('Action:', '');
            const idB = b.__ref.replace('Action:', '');
            return idToDate(idB).getTime() - idToDate(idA).getTime();
          });
          return sorted;
        },
      },
      cards: {
        // We have to use it if both read and merge function are defined on the field
        // Otherwise Apollo replaces it with `keyArgs: false`
        // https://github.com/apollographql/apollo-client/blob/2553695750f62657542792e22d0abe9b50a7dab2/src/cache/inmemory/policies.ts#L462
        keyArgs: defaultKeyArgsFunction,
        read: saveParentId,
        // eslint-disable-next-line @trello/no-module-logic
        merge: addParentConnection(boardToCardsRelation),
      },
      prefs: {
        merge: true,
      },
      templateGallery: {
        merge: true,
      },
      myPrefs: {
        merge: mergeIncomingAndFillNulls,
      },
      members: {
        merge: (existing, incoming, { readField }) => {
          incoming?.forEach((member: Reference) => {
            addMemberToIdCache(member, readField);
          });
          return mergeArrays(existing ?? [], incoming ?? []);
        },
      },
      limits: {
        merge: true,
      },
    },
    merge: (existing, incoming, { mergeObjects, readField }) => {
      const id =
        (existing !== undefined && readField('id', existing)) || incoming?.id;
      const ari =
        (existing !== undefined && readField('nodeId', existing)) ||
        incoming?.nodeId;
      const shortLink =
        (existing !== undefined && readField('shortLink', existing)) ||
        incoming?.shortLink;
      if (id && shortLink && !idCache.getBoardId(shortLink)) {
        idCache.setBoardId(shortLink, id);
      }
      if (ari && shortLink && !idCache.getBoardAri(shortLink)) {
        idCache.setBoardAri(shortLink, ari);
      }

      return mergeObjects(existing, incoming);
    },
  },
  List: {
    fields: {
      cards: {
        // We have to use it if both read and merge function are defined on the field
        // Otherwise Apollo replaces it with `keyArgs: false`
        // https://github.com/apollographql/apollo-client/blob/2553695750f62657542792e22d0abe9b50a7dab2/src/cache/inmemory/policies.ts#L462
        keyArgs: defaultKeyArgsFunction,
        read: saveParentId,
        // eslint-disable-next-line @trello/no-module-logic
        merge: addParentConnection(listToCardsRelation),
      },
    },
  },
  Card: {
    fields: {
      badges: {
        merge: true,
      },
      cover: {
        merge: true,
      },
      limits: {
        merge: true,
      },
    },
    merge: (existing, incoming, { mergeObjects, readField, cache }) => {
      const id =
        (existing !== undefined && readField('id', existing)) ||
        incoming?.id ||
        readField('id', incoming);
      const ari =
        (existing !== undefined && readField('nodeId', existing)) ||
        incoming?.nodeId;
      const shortLink =
        (existing !== undefined && readField('shortLink', existing)) ||
        incoming?.shortLink ||
        readField('shortLink', incoming);
      if (id && shortLink && !idCache.getCardId(shortLink)) {
        idCache.setCardId(shortLink, id);
      }
      if (ari && shortLink && !idCache.getCardAri(shortLink)) {
        idCache.setCardAri(shortLink, ari);
      }

      /**
       * when we add a card via socket update or adding directly, the response
       * from server will not contain checklists. If we don't do the following,
       * we'd make network requests to get data we already have because of a cache
       * miss for checklists. To fix that, this writes the an empty checklists
       * array to the cache when a card is added and there were not previously
       * checklists present on the card in the cache
       */
      const checklists = cache.readFragment({
        id: cache.identify(existing || incoming),
        fragment: gql`
          fragment CardChecklistsRead on Card {
            checklists {
              id
            }
          }
        `,
      });
      if (!checklists) {
        cache.writeFragment({
          id: cache.identify(existing || incoming),
          fragment: gql`
            fragment CardChecklistsWrite on Card {
              checklists(filter: all) {
                id
              }
              checklistsDue: checklists(filter: due) {
                id
              }
              checklistNoArgs: checklists {
                id
              }
            }
          `,
          data: {
            checklists: [],
            checklistsDue: [],
            checklistNoArgs: [],
          },
        });
      }

      return mergeObjects(existing, incoming);
    },
  },
  CardEntity: {
    // This is needed because the CardEntity cache entry was being refrenced by multiple notifications.
    // This would cause data to be incorrectly overwritten for fields not always returned by server,
    // because it would always use the 'last' value, which would sometimes be null.
    keyFields: () => `CardEntity:${uuidv4()}`,
  },
  Checklist: {
    fields: {
      pos: {
        // -1 means position is unknown. See `calcPos` in app/scripts/lib/util/index.js
        // eslint-disable-next-line @trello/no-module-logic
        read: readWithDefault(-1),
      },
    },
  },
  CustomFieldItem: {
    fields: {
      value: {
        merge: true,
      },
    },
  },
  Enterprise: {
    fields: {
      paidAccount: {
        merge: true,
      },
      organizations: {
        keyArgs: ['query', 'activeSince', 'inactiveSince'],
        merge(existing, incoming) {
          return {
            ...incoming,
            organizations: mergeArrays(
              existing?.organizations ?? [],
              incoming.organizations,
            ),
          };
        },
      },
      claimableOrganizations: {
        keyArgs: ['name', 'activeSince', 'inactiveSince'],
        read(existing, { args }) {
          return existing && existing?.cursor === args?.cursor
            ? undefined
            : existing;
        },
        merge(existing, incoming) {
          return {
            ...incoming,
            organizations: mergeArrays(
              existing?.organizations || [],
              incoming.organizations,
            ),
          };
        },
      },
      prefs: {
        merge: true,
      },
      organizationPrefs: {
        merge: true,
      },
    },
  },
  Organization: {
    fields: {
      paidAccount: {
        merge: true,
      },
      prefs: {
        merge: true,
      },
      cards: {
        keyArgs: (
          args: Partial<OrganizationCardsArgs> | null,
          { fieldName },
        ): string => {
          if (!args) {
            // https://github.com/apollographql/apollo-client/blob/d403a072b81fb9b10102d19ee636fa56186f9385/src/cache/inmemory/policies.ts#L267
            return fieldName;
          }
          const { limit, cursor, date, ...rest } = args;

          const keyObj = {
            ...rest,
            // `date` is not a keyArg since it's used for pagination on the
            // calendar. However, its absence or presence is a keyArg, because
            // it indicates whether we are paginating cards by `cursor` (table
            // view) or loading cards by date range (calendar view). This
            // computation is why keyArgs is a function instead of array for
            // this field.
            hasDateRange: Boolean(date),
          };

          return `${fieldName}:${JSON.stringify(keyObj)}`;
        },
        merge: (existing, incoming) => {
          return {
            ...incoming,
            cards: mergeArrays(existing?.cards || [], incoming?.cards || []),
          };
        },
      },
      members: {
        merge: (existing, incoming, { readField }) => {
          incoming?.forEach((member: Reference) => {
            addMemberToIdCache(member, readField);
          });
          return mergeArrays(existing ?? [], incoming ?? []);
        },
      },
      /**
       * API for limits will return no count field until the org
       * hits the warnAt threshold, resulting in a cache miss for orgs.
       * Defaulting to null here fixing excessive requests for org
       */
      limits: {
        merge(existing: Organization_Limits, incoming: Organization_Limits) {
          return {
            ...existing,
            ...incoming,
            orgs: {
              ...(existing?.orgs || {}),
              ...(incoming?.orgs || {}),
              freeBoardsPerOrg: {
                ...(existing?.orgs?.freeBoardsPerOrg || {}),
                ...(incoming?.orgs?.freeBoardsPerOrg || {}),
                count:
                  incoming?.orgs?.freeBoardsPerOrg?.count ||
                  existing?.orgs?.freeBoardsPerOrg?.count ||
                  null,
              },
            },
          };
        },
      },
      enterprise: {
        merge: true,
      },
      enterpriseJoinRequest: {
        merge: true,
      },
      domain: {
        merge: true,
      },
    },
    merge: (existing, incoming, { mergeObjects, readField }) => {
      const id =
        (existing !== undefined && readField('id', existing)) || incoming?.id;
      const ari =
        (existing !== undefined && readField('nodeId', existing)) ||
        incoming?.nodeId;
      const name =
        (existing !== undefined && readField('name', existing)) ||
        incoming?.name;
      if (id && name && !idCache.getWorkspaceId(name)) {
        idCache.setWorkspaceId(name, id);
      }
      if (ari && name && !idCache.getWorkspaceAri(name)) {
        idCache.setWorkspaceAri(name, ari);
      }
      return mergeObjects(existing, incoming);
    },
  },
  Member: {
    fields: {
      boardStars: {
        merge(existing = [], incoming: unknown[]) {
          return [...incoming];
        },
      },
      prefs: {
        merge: true,
      },
      paidAccount: {
        merge: true,
      },
      domain: {
        merge: true,
      },
      nonPublic: {
        merge: mergeIncomingAndFillNulls,
      },
    },
    merge: (existing, incoming, { mergeObjects, readField }) => {
      addMemberToIdCache(incoming, readField);
      return mergeObjects(existing, incoming);
    },
  },
  Collaborator: {
    fields: {
      nonPublic: {
        merge: mergeIncomingAndFillNulls,
      },
    },
  },
  TrelloSubscriptionApi: {
    merge: true,
  },
  TrelloBoard: {
    fields: {
      labels: {
        keyArgs: false,
      },
      prefs: {
        merge: true,
      },
      viewer: {
        merge: true,
      },
    },
  },
  TrelloBoardUpdated: {
    merge: false,
  },
  TrelloLabelConnection: {
    fields: {
      edges: {
        read: (existing: Edge[], { canRead }) =>
          (existing ?? []).filter((edge) => canRead(edge.node)),
        merge: (existing: Edge[], incoming: Edge[]) =>
          mergeArrays(
            existing ?? [],
            incoming ?? [],
            (edge) => edge.node.__ref,
          ),
      },
    },
  },
  TrelloMember: {
    merge: (existing, incoming, { mergeObjects, readField }) => {
      addMemberToIdCache(incoming, readField);
      return mergeObjects(existing, incoming);
    },
  },
  TrelloCard: {
    fields: {
      customFieldItems: {
        keyArgs: false,
      },
      list: {
        keyArgs: false,
      },
      members: {
        keyArgs: false,
      },
      sticker: {
        keyArgs: false,
      },
    },
  },
  TrelloPlannerCalendar: {
    fields: {
      events: {
        keyArgs: ['filter'],
        merge: (existing, incoming) => ({
          ...existing,
          ...incoming,
          edges: mergeArrays(
            existing?.edges ?? [],
            incoming?.edges ?? [],
            (edge: Edge) => edge.node.__ref,
          ),
          pageInfo: {
            ...existing?.pageInfo,
            ...incoming?.pageInfo,
          },
        }),
      },
    },
  },
  TrelloPlannerCalendarAccount: {
    fields: {
      // eslint-disable-next-line @trello/no-module-logic
      providerCalendars: relayStylePagination(false),
      enabledCalendars: {
        merge: (existing, incoming) => ({
          ...existing,
          ...incoming,
          edges: mergeArrays(
            existing?.edges ?? [],
            incoming?.edges ?? [],
            (edge: Edge) => edge.node.__ref,
          ),
          pageInfo: {
            ...existing?.pageInfo,
            ...incoming?.pageInfo,
          },
        }),
        keyArgs: false,
      },
    },
  },
  TrelloPlannerCalendarAccountConnection: {
    fields: {
      edges: {
        read: (existing: Edge[], { canRead }) =>
          (existing ?? []).filter((edge) => canRead(edge.node)),
      },
    },
  },
  TrelloPlannerCalendarEvent: {
    fields: {
      cards: {
        keyArgs: false,
      },
    },
  },
  TrelloPlannerCalendarEventConnection: {
    fields: {
      edges: {
        read: (existing: Edge[], { canRead }) =>
          (existing ?? []).filter((edge) => canRead(edge.node)),
      },
    },
  },
  TrelloPlannerCalendarEventCardConnection: {
    fields: {
      edges: {
        read: (existing: Edge[], { canRead }) =>
          (existing ?? []).filter((edge) => canRead(edge.node)),
        merge(
          existingEdges: Edge[],
          incomingEdges: Edge[],
          { readField, cache },
        ) {
          if (Array.isArray(incomingEdges)) {
            // We need to update the Apollo cache `Card` and `Board` (client side
            // models) entries with data coming in from the Planner query `TrelloCard`
            // and `TrelloBoard` (native GraphQl models)
            incomingEdges.forEach((edge: StoreObject) => {
              // The card associated with the event
              const eventCard = readField<TrelloPlannerCalendarEventCard>(
                'node',
                edge,
              );
              // Ref of the TrelloCard
              const trelloCardRef = readField<TrelloCard>('card', eventCard);
              const cardId = readField<string>('objectId', trelloCardRef);
              const trelloCardBadges = readField<TrelloCardBadges>(
                'badges',
                trelloCardRef,
              );
              const trelloCardCover = readField<TrelloCardCover>(
                'cover',
                trelloCardRef,
              );
              const trelloCardCustomFieldItems =
                readField<TrelloCustomFieldItemConnection>(
                  'customFieldItems',
                  trelloCardRef,
                );

              // Ref of the TrelloList
              const trelloCardListRef = readField<TrelloList>(
                'list',
                trelloCardRef,
              );
              // Ref of the TrelloBoard
              const trelloCardBoardRef = readField<TrelloBoard>(
                'board',
                trelloCardListRef,
              );

              const boardId = readField<string>('objectId', trelloCardBoardRef);
              if (!boardId) {
                return null;
              }

              const boardData = formatTrelloBoardData(
                readField,
                trelloCardBoardRef,
                boardId,
              );

              const trelloCardLabelsData = formatTrelloLabelsData(
                readField,
                trelloCardRef,
              );

              const trelloCardListData = formatTrelloListData(
                readField,
                trelloCardListRef,
                boardId,
              );
              const trelloCardCoverData = formatTrelloCardCoverData(
                readField,
                trelloCardCover,
              );
              const trelloCardBadgesData = formatTrelloCardBadgesData(
                readField,
                trelloCardBadges,
              );

              const cardMemberData = formatTrelloCardMemberData(
                readField,
                trelloCardRef,
              );

              const cardCustomFieldItemsData =
                formatTrelloCardCustomFieldItemsData(
                  readField,
                  trelloCardCustomFieldItems,
                );

              const cardStickerData = formatTrelloStickerData(
                readField,
                trelloCardRef,
              );

              const trelloCardData = {
                objectId: cardId,
                badges: trelloCardBadgesData,
                board: boardData,
                closed: readField<boolean>('closed', trelloCardRef),
                cover: trelloCardCoverData,
                customFieldItems: cardCustomFieldItemsData,
                isTemplate: readField<boolean>('isTemplate', trelloCardRef),
                labels: trelloCardLabelsData,
                list: trelloCardListData,
                mirrorSourceId: readField<string>(
                  'mirrorSourceId',
                  trelloCardRef,
                ),
                members: cardMemberData,
                name: readField<string>('name', trelloCardRef),
                role: readField<TrelloCardRole>('role', trelloCardRef),
                shortLink: readField<string>('shortLink', trelloCardRef),
                stickers: cardStickerData,
                url: readField<string>('url', trelloCardRef),
              };

              const isTrelloCardDataValid =
                validateTrelloCardData(trelloCardData);
              const isTrelloBoardDataValid = validateTrelloBoardData(boardData);

              if (!isTrelloCardDataValid || !isTrelloBoardDataValid) {
                return null;
              }

              const mappedBoardData = mapTrelloBoardDataToBoard(
                boardData,
                trelloCardData,
              );

              const mappedCardData = mapTrelloCardDataToCard(trelloCardData);

              const mappedCardMemberData =
                mapTrelloMemberDataToMember(cardMemberData);
              if (mappedBoardData) {
                cache.writeFragment<CardFrontBoardFragment>({
                  id: cache.identify({
                    __typename: 'Board',
                    id: boardId,
                  }),
                  fragment: CardFrontBoardFragmentDoc,
                  data: {
                    __typename: 'Board',
                    ...mappedBoardData,
                  },
                });
                const { customFields } = mappedBoardData;
                if (customFields) {
                  cache.writeFragment<CardFrontBoardCustomFieldsFragment>({
                    id: cache.identify({
                      __typename: 'Board',
                      id: boardId,
                    }),
                    fragment: CardFrontBoardCustomFieldsFragmentDoc,
                    data: {
                      __typename: 'Board',
                      id: mappedBoardData.id,
                      customFields,
                    },
                  });
                }

                if (mappedCardData) {
                  cache.writeFragment<CardFrontCombinedCardFragment>({
                    id: cache.identify({
                      __typename: 'Card',
                      id: cardId,
                    }),
                    fragment: CardFrontCombinedCardFragmentDoc,
                    data: {
                      __typename: 'Card',
                      ...mappedCardData,
                    },
                  });
                }
              }

              if (Array.isArray(mappedCardMemberData)) {
                mappedCardMemberData.forEach((memberData) => {
                  const cardMemberId = memberData?.id;
                  cache.writeFragment<CardFrontMemberAvatarFragment>({
                    id: cache.identify({
                      __typename: 'Member',
                      id: cardMemberId,
                    }),
                    fragment: CardFrontMemberAvatarFragmentDoc,
                    data: {
                      __typename: 'Member',
                      ...memberData,
                    },
                  });
                });
              }
            });
          }

          return mergeArrays(
            existingEdges ?? [],
            incomingEdges ?? [],
            (edge: Edge) => edge.node.__ref,
          );
        },
      },
    },
  },
  TrelloPlannerCalendarEventDeleted: {
    merge: (existing, incoming, { cache, readField }) => {
      const eventToRemoveId = readField('id', incoming);
      if (eventToRemoveId) {
        cache.evict({
          id: cache.identify({
            __typename: 'TrelloPlannerCalendarEvent',
            id: eventToRemoveId,
          }),
        });
        cache.gc();
      }
      return incoming;
    },
  },
  TrelloPlannerCalendarEventCardDeleted: {
    merge: (existing, incoming, { cache, readField }) => {
      const cardToRemoveId = readField('id', incoming);
      if (cardToRemoveId) {
        cache.evict({
          id: cache.identify({
            __typename: 'TrelloPlannerCalendarEventCard',
            id: cardToRemoveId,
          }),
        });
        cache.gc();
      }
      return incoming;
    },
  },
};
