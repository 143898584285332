/* eslint-disable @trello/no-module-logic */
// If you are adding a new route, include the route to GAS screen mapping in
// `routeScreens` in packages/atlassian-analytics/src/getScreenFromUrl.ts
// and include the route to workspace getter mapping in
// `workspaceGetterMap` in packages/workspaces/src/getWorkspaceFromPathname.ts

import {
  dangerouslyConvertPrivacyString,
  type PIIString,
} from '@trello/privacy';

import { getTokensFromPattern } from './getTokensFromPattern';

export const RouteId = {
  ACCOUNT: 'account',
  OLD_ACCOUNT: 'oldAccount',
  WORKSPACE_BILLING: 'workspaceBilling',
  BILLING: 'billing',
  BLANK: 'blank',
  BOARD: 'board',
  BOARD_OLD: 'boardOld',
  BOARD_REFERRAL: 'boardReferral',
  CARD: 'card',
  CARD_OLD: 'cardOld',
  CARD_AND_BOARD_OLD: 'cardAndBoardOld',
  CREATE_FIRST_BOARD: 'createFirstBoard',
  CREATE_FIRST_TEAM: 'createFirstTeam',
  CREATE_WORKSPACE: 'createWorkspace',
  DOUBLE_SLASH: 'doubleSlash',
  ENTERPRISE_ADMIN: 'enterpriseAdmin',
  ENTERPRISE_ADMIN_TAB: 'enterpriseAdminTab',
  ERROR_PAGE: 'errorPage',
  GO: 'go',
  INVITE_ACCEPT_BOARD: 'inviteAcceptBoard',
  INVITE_ACCEPT_TEAM: 'inviteAcceptTeam',
  MEMBER_ACTIVITY: 'memberActivity',
  OLD_MEMBER_ACTIVITY: 'oldMemberActivity',
  MEMBER_ALL_BOARDS: 'memberAllBoards',
  OLD_MEMBER_ALL_BOARDS: 'oldMemberAllBoards',
  MEMBER_CARDS: 'memberCards',
  OLD_MEMBER_CARDS: 'oldMemberCards',
  MEMBER_CARDS_FOR_ORG: 'memberCardsForOrg',
  OLD_MEMBER_CARDS_FOR_ORG: 'oldMemberCardsForOrg',
  MEMBER_HOME: 'memberHome',
  MEMBER_HOME_WORKSPACE_BOARDS: 'memberHomeBoards',
  OLD_MEMBER_HOME_WORKSPACE_BOARDS: 'oldMemberHomeBoards',
  MEMBER_PROFILE_SECTION: 'memberProfile',
  MEMBER_TASKS: 'memberTasks',
  MEMBER_LABS: 'memberLabs',
  OLD_MEMBER_LABS: 'oldMemberLabs',
  OLD_ORGANIZATION_GUESTS: 'oldOrganizationGuests',
  OLD_ORGANIZATION_MEMBERS: 'oldOrganizationMembers',
  OLD_ORGANIZATION_REQUESTS: 'oldOrganizationRequests',
  ORGANIZATION_BOARDS: 'organizationBoards',
  ORGANIZATION_BY_ID: 'organizationById',
  ORGANIZATION_EXPORT: 'organizationExport',
  OLD_ORGANIZATION_EXPORT: 'oldOrganizationExport',
  ORGANIZATION_FREE_TRIAL: 'organizationFreeTrial',
  OLD_ORGANIZATION_FREE_TRIAL: 'oldOrganizationFreeTrial',
  ORGANIZATION_GUESTS: 'organizationGuests',
  ORGANIZATION_REQUESTS: 'organizationRequests',
  ORGANIZATION_MEMBER_CARDS: 'organizationMemberCards',
  ORGANIZATION_MEMBERS: 'organizationMembers',
  ORGANIZATION_POWER_UPS: 'organizationPowerUps',
  OLD_ORGANIZATION_POWER_UPS: 'oldOrganizationPowerUps',
  ORGANIZATION_TABLES: 'organizationTables',
  OLD_ORGANIZATION_TABLES: 'oldOrganizationTables',
  POWER_UP_ADMIN: 'powerUpAdmin',
  POWER_UP_EDIT: 'powerUpEdit',
  POWER_UP_PUBLIC_DIRECTORY: 'powerUpPublicDirectory',
  PROFILE: 'profile',
  REDEEM: 'redeem',
  SEARCH: 'search',
  OPEN_SOURCE_ATTRIBUTIONS: 'openSourceAttributionsPage',
  SELECT_ORG_TO_UPGRADE: 'selectOrgToUpgrade',
  SELECT_TEAM_TO_UPGRADE: 'selectTeamToUpgrade',
  SHORTCUTS: 'shortcuts',
  SHORTCUTS_OVERLAY: 'shortcutsOverlay',
  ORGANIZATION_GETTING_STARTED: 'teamGettingStarted',
  OLD_ORGANIZATION_GETTING_STARTED: 'oldTeamGettingStarted',
  ORGANIZATION_HIGHLIGHTS: 'teamHighlights',
  OLD_ORGANIZATION_HIGHLIGHTS: 'oldTeamHighlights',
  ORGANIZATION_REPORTS: 'teamReports',
  OLD_ORGANIZATION_REPORTS: 'oldTeamReports',
  TEMPLATES: 'templates',
  TEMPLATES_RECOMMEND: 'templatesRecommend',
  TO: 'to',
  USER_OR_ORG: 'userOrOrg',
  WORKSPACE_DEFAULT_CUSTOM_TABLE_VIEW: 'workspaceDefaultCustomTableView',
  OLD_WORKSPACE_DEFAULT_CUSTOM_TABLE_VIEW: 'oldWorkspaceDefaultCustomTableView',
  WORKSPACE_VIEW: 'workspaceView',
  WORKSPACE_DEFAULT_CUSTOM_CALENDAR_VIEW: 'workspaceDefaultCustomCalendarView',
  OLD_WORKSPACE_DEFAULT_CUSTOM_CALENDAR_VIEW:
    'oldWorkspaceDefaultCustomCalendarView',
} as const;
export type RouteIdType = (typeof RouteId)[keyof typeof RouteId];

export type RouteParam = {
  [key: string]: PIIString | string | null;
} | null;

class Route<BaseParams extends RouteParam, ParsedParams = BaseParams> {
  id: RouteIdType;
  pattern: string;
  regExp: RegExp;
  routeParamsToPathname: (params: ParsedParams) => string;
  parseRouteParams?: (params: BaseParams) => ParsedParams;

  constructor({
    id,
    pattern,
    regExp,
    routeParamsToPathname,
    parseRouteParams,
  }: {
    id: RouteIdType;
    pattern: string;
    regExp: RegExp;
    routeParamsToPathname: (params: ParsedParams) => string;
    parseRouteParams?: (params: BaseParams) => ParsedParams;
  }) {
    this.id = id;
    this.pattern = pattern;
    this.regExp = regExp;
    this.routeParamsToPathname = routeParamsToPathname;
    this.parseRouteParams = parseRouteParams;
  }

  /**
   * Will retrieve the tokens from the pathname by using a RegExp
   * against the pattern defined for the route.
   * @param pathname window.location.pathname
   * @returns tokenized route parameters
   */
  public getRouteParams(pathname: string): ParsedParams {
    const routeTokens = getTokensFromPattern(this.pattern);
    const routeTokenValues = pathname.slice(1).match(this.regExp);

    const params = routeTokens.reduce(
      (accParams, token, i) => ({
        ...accParams,
        [token]: routeTokenValues?.[i + 1] || null,
      }),
      {},
    ) as BaseParams;

    if (this.parseRouteParams) {
      return this.parseRouteParams(params);
    }

    return params as unknown as ParsedParams;
  }
}

const account = new Route<{ name: string }>({
  id: RouteId.ACCOUNT,
  pattern: 'w/:name/account',
  regExp: new RegExp('^w/([^/]+)/account$'),
  routeParamsToPathname: ({ name }) => `/w/${name}/account`,
});
const oldAccount = new Route<{ name: string }>({
  id: RouteId.OLD_ACCOUNT,
  pattern: ':name/account(#*locationHash)',
  regExp: new RegExp('^([^/]+)/account((#(.*))?)$'),
  /**
   * @deprecated
   */
  routeParamsToPathname: ({ name }) => `/${name}/account`,
});
const workspaceBilling = new Route<{ name: string }>({
  id: RouteId.WORKSPACE_BILLING,
  pattern: 'w/:name/billing(?*query)',
  regExp: new RegExp('^w/([^/]+)/billing(?:\\?(.*?))?$'),
  routeParamsToPathname: ({ name }) => `/w/${name}/billing`,
});
const billing = new Route<{ name: string }>({
  id: RouteId.BILLING,
  pattern: ':name/billing(?*query)',
  regExp: new RegExp('^([^/]+)/billing(?:\\?(.*?))?$'),
  routeParamsToPathname: ({ name }) => `/${name}/billing`,
});
const blank = new Route({
  id: RouteId.BLANK,
  pattern: 'blank',
  regExp: new RegExp('^blank$'),
  routeParamsToPathname: () => `/blank`,
});

export interface BoardViewParams {
  shortLink: string;
  shortName: string;
  view: 'board';
}
export interface BoardCalendarViewParams {
  shortLink: string;
  shortName: string;
  view: 'calendar-view';
  day?: string;
  month?: string;
  year?: string;
}
export interface BoardCalendarPupViewParams {
  shortLink: string;
  shortName: string;
  view: 'calendar';
  day?: string;
  month?: string;
  year?: string;
}
export interface BoardDashboardViewParams {
  shortLink: string;
  shortName: string;
  view: 'dashboard';
}
export interface BoardMapViewParams {
  shortLink: string;
  shortName: string;
  view: 'map';
  lat?: number | string;
  lon?: number | string;
  zoom?: string;
}
export interface BoardPowerUpViewParams {
  shortLink: string;
  shortName: string;
  view: 'power-up';
  powerUpId: string;
}

type BoardPowerUpsSection =
  | 'category'
  | 'custom'
  | 'enabled'
  | 'made-by-trello'
  | 'search';

export interface BoardPowerUpsViewParams {
  shortLink: string;
  shortName: string;
  view: 'power-ups';
  category?:
    | 'analytics-reporting'
    | 'automation'
    | 'board-utilities'
    | 'communication-collaboration'
    | 'developer-tools'
    | 'file-management'
    | 'hr-operations'
    | 'it-project-management'
    | 'marketing-social-media'
    | 'product-design'
    | 'sales-support';
  section?: BoardPowerUpsSection;
}
export interface BoardTableViewParams {
  shortLink: string;
  shortName: string;
  view: 'table';
}
export interface BoardTimelineViewParams {
  shortLink: string;
  shortName: string;
  view: 'timeline';
}
type ButlerScreen = 'account' | 'edit' | 'log' | 'reports' | 'usage';
type ButlerTab =
  | 'board-buttons'
  | 'card-buttons'
  | 'connected-apps'
  | 'on-dates'
  | 'rules'
  | 'schedule'
  | 'suggestions';
export interface BoardButlerViewParams {
  shortLink: string;
  shortName: string;
  view: 'butler';
  tab?: ButlerTab;
  screen?: ButlerScreen;
  paramOrCommand?: string;
}
export interface BoardMemberActivityParams {
  shortLink: string;
  shortName: string;
  view: 'member';
  username: string;
}

export type BoardViews =
  | BoardButlerViewParams
  | BoardCalendarPupViewParams
  | BoardCalendarViewParams
  | BoardDashboardViewParams
  | BoardMapViewParams
  | BoardMemberActivityParams
  | BoardPowerUpsViewParams
  | BoardPowerUpViewParams
  | BoardTableViewParams
  | BoardTimelineViewParams
  | BoardViewParams;

const board = new Route<{ shortLink: string; path?: string }, BoardViews>({
  id: RouteId.BOARD,
  pattern: 'b/:shortLink(/*path)',
  regExp: new RegExp('^b/([^/]+)(?:/(.*?))?$'),
  routeParamsToPathname: (params) => {
    let baseUrl: string;
    const { view, shortLink, shortName } = params;

    if (view === 'board' || !view) {
      baseUrl = `/b/${shortLink}/${shortName}`;
    } else {
      baseUrl = `/b/${shortLink}/${shortName}/${view}`;
    }

    if (view === 'map') {
      const { lat, lon, zoom } = params;
      baseUrl += `/${lat}/${lon}/${zoom}`;
    } else if (view === 'power-up') {
      const { powerUpId } = params;
      baseUrl += `/${powerUpId}`;
    } else if (view === 'power-ups') {
      const { category, section } = params;
      if (category) {
        baseUrl += `/category/${category}`;
      } else if (section) {
        baseUrl += `/${section}`;
      }
    } else if (view === 'butler') {
      const { tab, screen, paramOrCommand } = params;
      if (tab) {
        baseUrl += `/${tab}`;
      }

      if (screen) {
        baseUrl += `/${screen}`;

        if (
          (screen === 'edit' || screen === 'log' || screen === 'reports') &&
          paramOrCommand
        ) {
          baseUrl += `/${paramOrCommand}`;
        }
      }
    } else if (view === 'member') {
      const { username } = params;

      baseUrl += `/${username}`;
    } else if (view === 'calendar') {
      const { year, month, day } = params;
      if (year) {
        baseUrl += `/${year}`;
      }
      if (month) {
        baseUrl += `/${month}`;
      }
      if (day) {
        baseUrl += `/${day}`;
      }
    } else if (view === 'calendar-view') {
      const { year, month, day } = params;
      if (year) {
        baseUrl += `/${year}`;
      }
      if (month) {
        baseUrl += `/${month}`;
      }
      if (day) {
        baseUrl += `/${day}`;
      }
    }

    return baseUrl;
  },
  parseRouteParams: ({ shortLink, path }) => {
    const parameters = path?.split('/') ?? [];
    const [shortName, view, ...rest] = parameters;
    switch (view) {
      case 'table':
        return { shortLink, shortName, view: 'table' };
      case 'calendar-view':
        return {
          shortLink,
          shortName,
          view: 'calendar-view',
          year: rest[0],
          month: rest[1],
          day: rest[2],
        };
      case 'timeline':
        return { shortLink, shortName, view: 'timeline' };
      case 'dashboard':
        return { shortLink, shortName, view: 'dashboard' };
      case 'map':
        return {
          shortLink,
          shortName,
          view: 'map',
          lat: rest[0],
          lon: rest[1],
          zoom: rest[2],
        };
      case 'calendar':
        return {
          shortLink,
          shortName,
          view: 'calendar',
          year: rest[0],
          month: rest[1],
          day: rest[2],
        };
      case 'power-up':
        return { shortLink, shortName, view: 'power-up', powerUpId: rest[0] };
      case 'power-ups':
        if (rest[0] === 'category') {
          return {
            shortLink,
            shortName,
            view: 'power-ups',
            category: rest[1] as
              | 'analytics-reporting'
              | 'automation'
              | 'board-utilities'
              | 'communication-collaboration'
              | 'developer-tools'
              | 'file-management'
              | 'hr-operations'
              | 'it-project-management'
              | 'marketing-social-media'
              | 'product-design'
              | 'sales-support',
          };
        } else {
          return {
            shortLink,
            shortName,
            view: 'power-ups',
            section: rest[0] as BoardPowerUpsSection,
          };
        }
      case 'butler': {
        const params: BoardButlerViewParams = {
          shortLink,
          shortName,
          view: 'butler',
        };

        if (['edit, log', 'reports'].includes(rest[0])) {
          // :slug/butler/reports/:reportType
          // :slug/butler/edit/:butlerCmd
          // :slug/butler/log/:butlerCmd
          params.screen = rest[0] as ButlerScreen;
          params.paramOrCommand = rest[1];
        } else {
          params.tab = rest[0] as ButlerTab;
          if (rest[1] === 'new') {
            // :slug/butler/:butlerTab/new
            params.paramOrCommand = rest[1];
          } else {
            // :slug/butler/:butlerTab
            // :slug/butler/:butlerTab/edit/:butlerCmd
            // :slug/butler/:butlerTab/log/:butlerCmd
            // :slug/butler/:butlerTab/usage
            // :slug/butler/:butlerTab/account
            params.screen = rest[1] as ButlerScreen;
            params.paramOrCommand = rest[2];
          }
        }

        return params;
      }
      case 'member': {
        const params: BoardMemberActivityParams = {
          shortLink,
          shortName,
          view: 'member',
          username: rest[0],
        };

        return params;
      }
      default:
        return { shortLink, shortName, view: 'board' };
    }
  },
});

const boardOld = new Route<{ path: string }>({
  id: RouteId.BOARD_OLD,
  pattern: 'board/*path',
  regExp: new RegExp('^board/(.*?)$'),
  routeParamsToPathname: ({ path }) => `/board/${path}`,
});
const boardReferral = new Route<{
  shortLink: string;
  name: string;
  referrerUsername: string;
}>({
  id: RouteId.BOARD_REFERRAL,
  pattern: 'b/:shortLink/:name/:referrerUsername/recommend',
  regExp: new RegExp('^b/([^/]+)/([^/]+)/([^/]+)/recommend$'),
  routeParamsToPathname: ({ shortLink, name, referrerUsername }) =>
    `/b/${shortLink}/${name}/${referrerUsername}/recommend`,
});
const card = new Route<{ shortLink: string; path: string }>({
  id: RouteId.CARD,
  pattern: 'c/:shortLink(/*path)',
  regExp: new RegExp('^c/([^/]+)(?:/(.*?))?$'),
  routeParamsToPathname: ({ shortLink, path }) => `/c/${shortLink}/${path}`,
});
const cardOld = new Route<{
  slug: string;
  idBoard: string;
  cardComponent: string;
}>({
  id: RouteId.CARD_OLD,
  pattern: 'card/(:slug/):idBoard/:cardComponent',
  regExp: new RegExp('^card/(?:([^/]+)/)?([^/]+)/([^/]+)$'),
  /**
   * @deprecated
   */
  routeParamsToPathname: ({ slug, idBoard, cardComponent }) =>
    `/card/${slug}/${idBoard}/${cardComponent}`,
});
const cardAndBoardOld = new Route<{
  slug: string;
  idBoard: string;
  cardComponent: string;
}>({
  id: RouteId.CARD_AND_BOARD_OLD,
  pattern: 'card/board/(:slug/):idBoard/:cardComponent',
  regExp: new RegExp('^card/board/(?:([^/]+)/)?([^/]+)/([^/]+)$'),
  /**
   * @deprecated
   */
  routeParamsToPathname: ({ slug, idBoard, cardComponent }) =>
    `/card/board/${slug}/${idBoard}/${cardComponent}`,
});
const createFirstBoard = new Route<null>({
  id: RouteId.CREATE_FIRST_BOARD,
  pattern: 'create-first-board(?*querystring)',
  regExp: new RegExp('^create-first-board(?:\\?(.*?))?$'),
  routeParamsToPathname: () => `/create-first-board`,
});
const createFirstTeam = new Route<null>({
  id: RouteId.CREATE_FIRST_TEAM,
  pattern: 'create-first-team(?*querystring)',
  regExp: new RegExp('^create-first-team(?:\\?(.*?))?$'),
  routeParamsToPathname: () => `/create-first-team`,
});
const createWorkspace = new Route<null>({
  id: RouteId.CREATE_WORKSPACE,
  pattern: 'create-workspace(?*querystring)',
  regExp: new RegExp('^create-workspace(?:\\?(.*?))?$'),
  routeParamsToPathname: () => `/create-workspace`,
});
const doubleSlash = new Route<{ search: string }>({
  id: RouteId.DOUBLE_SLASH,
  pattern: '/*search',
  regExp: new RegExp('^/(.*?)$'),
  routeParamsToPathname: ({ search }) => `/${search}`,
});
const enterpriseAdmin = new Route<{ name: string }>({
  id: RouteId.ENTERPRISE_ADMIN,
  pattern: 'e/:name/admin',
  regExp: new RegExp('^e/([^/]+)/admin$'),
  routeParamsToPathname: ({ name }) => `/e/${name}/admin`,
});
const enterpriseAdminTab = new Route<{ name: string; tab: string }>({
  id: RouteId.ENTERPRISE_ADMIN_TAB,
  pattern: 'e/:name/admin/*tab',
  regExp: new RegExp('^e/([^/]+)/admin/(.*?)$'),
  routeParamsToPathname: ({ name, tab }) => `/e/${name}/admin/${tab}`,
});
const errorPage = new Route<null>({
  id: RouteId.ERROR_PAGE,
  pattern: '*splat',
  regExp: new RegExp('^(.*?)$'),
  routeParamsToPathname: () => '',
});
const go = new Route<{ search: string }>({
  id: RouteId.GO,
  pattern: 'go/*search',
  regExp: new RegExp('^go/(.*?)$'),
  routeParamsToPathname: ({ search }) => `/go/${search}`,
});
const inviteAcceptBoard = new Route<null>({
  id: RouteId.INVITE_ACCEPT_BOARD,
  pattern: 'invite/accept-board',
  regExp: new RegExp('^invite/accept-board$'),
  routeParamsToPathname: () => '/invite/accept-board',
});
const inviteAcceptTeam = new Route<null>({
  id: RouteId.INVITE_ACCEPT_TEAM,
  pattern: 'invite/accept-team',
  regExp: new RegExp('^invite/accept-team$'),
  routeParamsToPathname: () => '/invite/accept-team',
});
const oldMemberActivity = new Route<{ username: PIIString }>({
  id: RouteId.OLD_MEMBER_ACTIVITY,
  pattern: ':username/activity',
  regExp: new RegExp('^([^/]+)/activity$'),
  /**
   * @deprecated
   */
  routeParamsToPathname: ({ username }) =>
    `/${dangerouslyConvertPrivacyString(username)}/activity`,
});
const memberActivity = new Route<{ username: PIIString }>({
  id: RouteId.MEMBER_ACTIVITY,
  pattern: 'u/:username/activity',
  regExp: new RegExp('^u/([^/]+)/activity$'),
  routeParamsToPathname: ({ username }) =>
    `/u/${dangerouslyConvertPrivacyString(username)}/activity`,
});
const memberAllBoards = new Route<{ username: PIIString }>({
  id: RouteId.MEMBER_ALL_BOARDS,
  pattern: 'u/:username/boards',
  regExp: new RegExp('^u/([^/]+)/boards$'),
  routeParamsToPathname: ({ username }) =>
    `/u/${dangerouslyConvertPrivacyString(username)}/boards`,
});
const oldMemberAllBoards = new Route<{ username: PIIString }>({
  id: RouteId.OLD_MEMBER_ALL_BOARDS,
  pattern: ':username/boards',
  regExp: new RegExp('^([^/]+)/boards$'),
  /**
   * @deprecated
   */
  routeParamsToPathname: ({ username }) =>
    `/${dangerouslyConvertPrivacyString(username)}/boards`,
});
const oldMemberCards = new Route<{ username: PIIString }>({
  id: RouteId.OLD_MEMBER_CARDS,
  pattern: ':username/cards',
  regExp: new RegExp('^([^/]+)/cards$'),
  /**
   * @deprecated
   */
  routeParamsToPathname: ({ username }) =>
    `/${dangerouslyConvertPrivacyString(username)}/cards`,
});
const memberCards = new Route<{ username: PIIString }>({
  id: RouteId.MEMBER_CARDS,
  pattern: 'u/:username/cards',
  regExp: new RegExp('^u/([^/]+)/cards$'),
  routeParamsToPathname: ({ username }) =>
    `/u/${dangerouslyConvertPrivacyString(username)}/cards`,
});
const oldMemberCardsForOrg = new Route<{
  username: PIIString;
  orgname: string;
}>({
  id: RouteId.OLD_MEMBER_CARDS_FOR_ORG,
  pattern: ':username/cards/:orgname',
  regExp: new RegExp('^([^/]+)/cards/([^/]+)$'),
  /**
   * @deprecated
   */
  routeParamsToPathname: ({ username, orgname }) =>
    `/${dangerouslyConvertPrivacyString(username)}/cards/${orgname}`,
});
const memberCardsForOrg = new Route<{ username: PIIString; orgname: string }>({
  id: RouteId.MEMBER_CARDS_FOR_ORG,
  pattern: 'u/:username/cards/:orgname',
  regExp: new RegExp('^u/([^/]+)/cards/([^/]+)$'),
  routeParamsToPathname: ({ username, orgname }) =>
    `/u/${dangerouslyConvertPrivacyString(username)}/cards/${orgname}`,
});
const memberHome = new Route<null>({
  id: RouteId.MEMBER_HOME,
  pattern: '',
  regExp: new RegExp('^$'),
  routeParamsToPathname: () => '',
});
const memberHomeBoards = new Route<{ orgname: string }>({
  id: RouteId.MEMBER_HOME_WORKSPACE_BOARDS,
  pattern: 'w/:orgname/home',
  regExp: new RegExp('^w/([^/]+)/home$'),
  routeParamsToPathname: ({ orgname }) => `/w/${orgname}/home`,
});
const oldMemberHomeBoards = new Route<{ orgname: string }>({
  id: RouteId.OLD_MEMBER_HOME_WORKSPACE_BOARDS,
  pattern: ':orgname/home',
  regExp: new RegExp('^([^/]+)/home$'),
  /**
   * @deprecated
   */
  routeParamsToPathname: ({ orgname }) => `/${orgname}/home`,
});
const memberProfile = new Route<{
  username: PIIString;
  section: string;
  subsection: string;
}>({
  id: RouteId.MEMBER_PROFILE_SECTION,
  pattern: 'u/:username(/:section)(/:subsection)(/)(#*locationHash)',
  regExp: new RegExp('^u/([^/]+)(?:/([^/]+)){0,2}(?:/)?$'),
  routeParamsToPathname: ({ username, section, subsection }) =>
    `/u/${dangerouslyConvertPrivacyString(username)}/${section}/${subsection}`,
});
const memberTasks = new Route<{ username: PIIString }>({
  id: RouteId.MEMBER_TASKS,
  pattern: ':username/tasks',
  regExp: new RegExp('^([^/]+)/tasks$'),
  routeParamsToPathname: ({ username }) =>
    `/${dangerouslyConvertPrivacyString(username)}/tasks`,
});
const memberLabs = new Route<{ username: PIIString }>({
  id: RouteId.MEMBER_LABS,
  pattern: 'u/:username/labs',
  regExp: new RegExp('^u/([^/]+)/labs$'),
  routeParamsToPathname: ({ username }) =>
    `/u/${dangerouslyConvertPrivacyString(username)}/labs`,
});
const oldMemberLabs = new Route<{ username: PIIString }>({
  id: RouteId.OLD_MEMBER_LABS,
  pattern: ':username/labs',
  regExp: new RegExp('^([^/]+)/labs$'),
  /**
   * @deprecated
   */
  routeParamsToPathname: ({ username }) =>
    `/${dangerouslyConvertPrivacyString(username)}/labs`,
});
const oldOrganizationGuests = new Route<{ name: string }>({
  id: RouteId.OLD_ORGANIZATION_GUESTS,
  pattern: ':name/members/guests',
  regExp: new RegExp('^([^/]+)/members/guests$'),
  /**
   * @deprecated
   */
  routeParamsToPathname: ({ name }) => `/${name}/members/guests`,
});
const oldOrganizationMembers = new Route<{ name: string }>({
  id: RouteId.OLD_ORGANIZATION_MEMBERS,
  pattern: ':name/members',
  regExp: new RegExp('^([^/]+)/members$'),
  /**
   * @deprecated
   */
  routeParamsToPathname: ({ name }) => `/${name}/members`,
});
const oldOrganizationRequests = new Route<{ name: string }>({
  id: RouteId.OLD_ORGANIZATION_REQUESTS,
  pattern: ':name/members/requests',
  regExp: new RegExp('^([^/]+)/members/requests$'),
  /**
   * @deprecated
   */
  routeParamsToPathname: ({ name }) => `/${name}/members/requests`,
});
const organizationById = new Route<{ id: string }>({
  id: RouteId.ORGANIZATION_BY_ID,
  pattern: 'org/:id',
  regExp: new RegExp('^org/([^/]+)$'),
  routeParamsToPathname: ({ id }) => `/org/${id}`,
});
const organizationExport = new Route<{ name: string }>({
  id: RouteId.ORGANIZATION_EXPORT,
  pattern: 'w/:name/export',
  regExp: new RegExp('^w/([^/]+)/export$'),
  routeParamsToPathname: ({ name }) => `/w/${name}/export`,
});
const oldOrganizationExport = new Route<{ name: string }>({
  id: RouteId.OLD_ORGANIZATION_EXPORT,
  pattern: ':name/export',
  regExp: new RegExp('^([^/]+)/export$'),
  /**
   * @deprecated
   */
  routeParamsToPathname: ({ name }) => `/${name}/export`,
});
const organizationFreeTrial = new Route<{ orgname: string }>({
  id: RouteId.ORGANIZATION_FREE_TRIAL,
  pattern: 'w/:orgname/free-trial',
  regExp: new RegExp('^w/([^/]+)/free-trial$'),
  routeParamsToPathname: ({ orgname }) => `/w/${orgname}/free-trial`,
});
const oldOrganizationFreeTrial = new Route<{ orgname: string }>({
  id: RouteId.OLD_ORGANIZATION_FREE_TRIAL,
  pattern: ':orgname/free-trial',
  regExp: new RegExp('^([^/]+)/free-trial$'),
  /**
   * @deprecated
   */
  routeParamsToPathname: ({ orgname }) => `/${orgname}/free-trial`,
});
const organizationGuests = new Route<{ name: string }>({
  id: RouteId.ORGANIZATION_GUESTS,
  pattern: 'w/:name/members/guests',
  regExp: new RegExp('^w/([^/]+)/members/guests$'),
  routeParamsToPathname: ({ name }) => `/w/${name}/members/guests`,
});
const organizationRequests = new Route<{ name: string }>({
  id: RouteId.ORGANIZATION_REQUESTS,
  pattern: 'w/:name/members/requests',
  regExp: new RegExp('^w/([^/]+)/members/requests$'),
  routeParamsToPathname: ({ name }) => `/w/${name}/members/requests`,
});
const organizationMemberCards = new Route<{
  name: string;
  username: PIIString;
}>({
  id: RouteId.ORGANIZATION_MEMBER_CARDS,
  pattern: ':name/:username/cards',
  regExp: new RegExp('^([^/]+)/([^/]+)/cards$'),
  routeParamsToPathname: ({ name, username }) =>
    `/${name}/${dangerouslyConvertPrivacyString(username)}/cards`,
});
const organizationMembers = new Route<{ name: string }>({
  id: RouteId.ORGANIZATION_MEMBERS,
  pattern: 'w/:name/members',
  regExp: new RegExp('^w/([^/]+)/members$'),
  routeParamsToPathname: ({ name }) => `/w/${name}/members`,
});
const organizationPowerUps = new Route<{ name: string }>({
  id: RouteId.ORGANIZATION_POWER_UPS,
  pattern: 'w/:name/power-ups',
  regExp: new RegExp('^w/([^/]+)/power-ups$'),
  routeParamsToPathname: ({ name }) => `/w/${name}/power-ups`,
});
const oldOrganizationPowerUps = new Route<{ name: string }>({
  id: RouteId.OLD_ORGANIZATION_POWER_UPS,
  pattern: ':name/power-ups',
  regExp: new RegExp('^([^/]+)/power-ups$'),
  /**
   * @deprecated
   */
  routeParamsToPathname: ({ name }) => `/${name}/power-ups`,
});
const organizationTables = new Route<{ name: string }>({
  id: RouteId.ORGANIZATION_TABLES,
  pattern: 'w/:name/tables(?*query)',
  regExp: new RegExp('^w/([^/]+)/tables(?:\\?(.*?))?$'),
  routeParamsToPathname: ({ name }) => `/w/${name}/tables`,
});
const oldOrganizationTables = new Route<{ name: string }>({
  id: RouteId.OLD_ORGANIZATION_TABLES,
  pattern: ':name/tables(?*query)',
  regExp: new RegExp('^([^/]+)/tables(?:\\?(.*?))?$'),
  /**
   * @deprecated
   */
  routeParamsToPathname: ({ name }) => `/${name}/tables`,
});
const teamGettingStarted = new Route<{ orgname: string }>({
  id: RouteId.ORGANIZATION_GETTING_STARTED,
  pattern: 'w/:orgname/getting-started',
  regExp: new RegExp('^w/([^/]+)/getting-started$'),
  routeParamsToPathname: ({ orgname }) => `/w/${orgname}/getting-started`,
});
const oldTeamGettingStarted = new Route<{ orgname: string }>({
  id: RouteId.OLD_ORGANIZATION_GETTING_STARTED,
  pattern: ':orgname/getting-started',
  regExp: new RegExp('^([^/]+)/getting-started$'),
  /**
   * @deprecated
   */
  routeParamsToPathname: ({ orgname }) => `/${orgname}/getting-started`,
});
const teamHighlights = new Route<{ orgname: string }>({
  id: RouteId.ORGANIZATION_HIGHLIGHTS,
  pattern: 'w/:orgname/highlights',
  regExp: new RegExp('^w/([^/]+)/highlights$'),
  routeParamsToPathname: ({ orgname }) => `/w/${orgname}/highlights`,
});
const oldTeamHighlights = new Route<{ orgname: string }>({
  id: RouteId.OLD_ORGANIZATION_HIGHLIGHTS,
  pattern: ':orgname/highlights',
  regExp: new RegExp('^([^/]+)/highlights$'),
  /**
   * @deprecated
   */
  routeParamsToPathname: ({ orgname }) => `/${orgname}/highlights`,
});
const oldTeamReports = new Route<{ orgname: string }>({
  id: RouteId.OLD_ORGANIZATION_REPORTS,
  pattern: ':orgname/reports',
  regExp: new RegExp('^([^/]+)/reports$'),
  /**
   * @deprecated
   */
  routeParamsToPathname: ({ orgname }) => `/${orgname}/reports`,
});
const teamReports = new Route<{ orgname: string }>({
  id: RouteId.ORGANIZATION_REPORTS,
  pattern: 'w/:orgname/reports',
  regExp: new RegExp('^w/([^/]+)/reports$'),
  routeParamsToPathname: ({ orgname }) => `/w/${orgname}/reports`,
});
const powerUpAdmin = new Route<{ section: string }>({
  id: RouteId.POWER_UP_ADMIN,
  pattern: 'power-ups/admin(/:section)(/)',
  regExp: new RegExp('^power-ups/admin(?:/([^/]+))?(?:/)?$'),
  routeParamsToPathname: ({ section }) => `/power-ups/admin/${section}`,
});
const powerUpEdit = new Route<{ idPlugin: string; subsection: string }>({
  id: RouteId.POWER_UP_EDIT,
  pattern: 'power-ups/:idPlugin/edit(/:subsection)(/)',
  regExp: new RegExp('^power-ups/([^/]+)/edit(?:/([^/]+))?(?:/)?$'),
  routeParamsToPathname: ({ idPlugin, subsection }) =>
    `/power-ups/${idPlugin}/edit/${subsection}`,
});
const powerUpPublicDirectory = new Route<{
  section: string;
  subsection: string;
}>({
  id: RouteId.POWER_UP_PUBLIC_DIRECTORY,
  pattern: 'power-ups(/:section)(/:subsection)(/)',
  regExp: new RegExp('^power-ups(?:/([^/]+))?(?:/([^/]+))?(?:/)?$'),
  routeParamsToPathname: ({ section, subsection }) =>
    `/power-ups/${section}/${subsection}`,
});
const profile = new Route<{ name: PIIString }>({
  id: RouteId.PROFILE,
  pattern: ':name/profile',
  regExp: new RegExp('^([^/]+)/profile$'),
  routeParamsToPathname: ({ name }) =>
    `/${dangerouslyConvertPrivacyString(name)}/profile`,
});
const redeem = new Route<null>({
  id: RouteId.REDEEM,
  pattern: 'redeem(?*code)',
  regExp: new RegExp('^redeem(?:\\?(.*?))?$'),
  routeParamsToPathname: () => `/redeem`,
});
const search = new Route<null>({
  id: RouteId.SEARCH,
  pattern: 'search(?*query)',
  regExp: new RegExp('^search(?:\\?(.*?))?$'),
  routeParamsToPathname: () => '/search',
});
const openSourceAttributionsPage = new Route<null>({
  id: RouteId.OPEN_SOURCE_ATTRIBUTIONS,
  pattern: 'attributions',
  regExp: new RegExp('^attributions$'),
  routeParamsToPathname: () => '/attributions',
});
const selectOrgToUpgrade = new Route<null>({
  id: RouteId.SELECT_ORG_TO_UPGRADE,
  pattern: 'select-org-to-upgrade',
  regExp: new RegExp('^select-org-to-upgrade$'),
  routeParamsToPathname: () => '/select-org-to-upgrade',
});
const selectTeamToUpgrade = new Route<null>({
  id: RouteId.SELECT_TEAM_TO_UPGRADE,
  pattern: 'select-team-to-upgrade',
  regExp: new RegExp('^select-team-to-upgrade$'),
  routeParamsToPathname: () => '/select-team-to-upgrade',
});
const shortcuts = new Route<null>({
  id: RouteId.SHORTCUTS,
  pattern: 'shortcuts',
  regExp: new RegExp('^shortcuts$'),
  routeParamsToPathname: () => '/shortcuts',
});
const shortcutsOverlay = new Route<null>({
  id: RouteId.SHORTCUTS_OVERLAY,
  pattern: 'shortcuts/overlay',
  regExp: new RegExp('^shortcuts/overlay$'),
  routeParamsToPathname: () => '/shortcuts/overlay',
});
const templates = new Route<{ category: string; templateSlug: string }>({
  id: RouteId.TEMPLATES,
  pattern: 'templates(/:category)(/:templateSlug)(/)',
  regExp: new RegExp('^templates(?:/([^/]+))?(?:/([^/]+))?(?:/)?$'),
  routeParamsToPathname: ({ category, templateSlug }) =>
    `/templates/${category}/${templateSlug}`,
});
const templatesRecommend = new Route<{
  category: string;
  templateSlug: string;
  referrerUsername: string;
}>({
  id: RouteId.TEMPLATES_RECOMMEND,
  pattern:
    'templates(/:category)(/:templateSlug)(/:referrerUsername)/recommend(/)',
  regExp: new RegExp(
    '^templates(?:/([^/]+))?(?:/([^/]+))?(?:/([^/]+))?/recommend(?:/)?$',
  ),
  routeParamsToPathname: ({ category, templateSlug, referrerUsername }) =>
    `/templates/${category}/${templateSlug}/${referrerUsername}/recommend`,
});
const to = new Route<{ search: string }>({
  id: RouteId.TO,
  pattern: 'to/*search',
  regExp: new RegExp('^to/(.*?)$'),
  routeParamsToPathname: (params) => `/to/${params.search}`,
});
const userOrOrg = new Route<{ name: string }>({
  id: RouteId.USER_OR_ORG,
  pattern: ':name(/)',
  regExp: new RegExp('^([^/]+)(?:/)?$'),
  routeParamsToPathname: ({ name }) => `/${name}`,
});
const organizationBoards = new Route<{ orgname: string }>({
  id: RouteId.ORGANIZATION_BOARDS,
  pattern: 'w/:orgname(/)',
  regExp: new RegExp('^w/([^/]+)(?:/)?$'),
  routeParamsToPathname: ({ orgname }) => `/w/${orgname}`,
});
const workspaceDefaultCustomTableView = new Route<{ orgname: string }>({
  id: RouteId.WORKSPACE_DEFAULT_CUSTOM_TABLE_VIEW,
  pattern: 'w/:orgname/views/table(?*query)',
  regExp: new RegExp('^w/([^/]+)/views/table(?:\\?(.*?))?$'),
  routeParamsToPathname: ({ orgname }) => `/w/${orgname}/views/table`,
});
const oldWorkspaceDefaultCustomTableView = new Route<{ orgname: string }>({
  id: RouteId.OLD_WORKSPACE_DEFAULT_CUSTOM_TABLE_VIEW,
  pattern: ':orgname/views/table(?*query)',
  regExp: new RegExp('^([^/]+)/views/table(?:\\?(.*?))?$'),
  /**
   * @deprecated
   */
  routeParamsToPathname: ({ orgname }) => `/${orgname}/views/table`,
});
const workspaceView = new Route<{ shortLink: string }>({
  id: RouteId.WORKSPACE_VIEW,
  pattern: 'v/:shortLink(/*path)',
  regExp: new RegExp('^v/([^/]+)(?:/(.*?))?$'),
  routeParamsToPathname: ({ shortLink }) => `/v/${shortLink}`,
});
const workspaceDefaultCustomCalendarView = new Route<{ orgname: string }>({
  id: RouteId.WORKSPACE_DEFAULT_CUSTOM_CALENDAR_VIEW,
  pattern: 'w/:orgname/views/calendar(?*query)',
  regExp: new RegExp('^w/([^/]+)/views/calendar(?:\\?(.*?))?$'),
  routeParamsToPathname: ({ orgname }) => `/w/${orgname}/views/calendar`,
});
const oldWorkspaceDefaultCustomCalendarView = new Route<{ orgname: string }>({
  id: RouteId.OLD_WORKSPACE_DEFAULT_CUSTOM_CALENDAR_VIEW,
  pattern: ':orgname/views/calendar(?*query)',
  regExp: new RegExp('^([^/]+)/views/calendar(?:\\?(.*?))?$'),
  /**
   * @deprecated
   */
  routeParamsToPathname: ({ orgname }) => `/${orgname}/views/calendar`,
});

export const routes = {
  [RouteId.ACCOUNT]: account,
  [RouteId.BILLING]: billing,
  [RouteId.BLANK]: blank,
  [RouteId.BOARD]: board,
  [RouteId.BOARD_OLD]: boardOld,
  [RouteId.BOARD_REFERRAL]: boardReferral,
  [RouteId.CARD]: card,
  [RouteId.CARD_AND_BOARD_OLD]: cardAndBoardOld,
  [RouteId.CARD_OLD]: cardOld,
  [RouteId.CREATE_FIRST_BOARD]: createFirstBoard,
  [RouteId.CREATE_FIRST_TEAM]: createFirstTeam,
  [RouteId.CREATE_WORKSPACE]: createWorkspace,
  [RouteId.DOUBLE_SLASH]: doubleSlash,
  [RouteId.ENTERPRISE_ADMIN]: enterpriseAdmin,
  [RouteId.ENTERPRISE_ADMIN_TAB]: enterpriseAdminTab,
  [RouteId.ERROR_PAGE]: errorPage,
  [RouteId.GO]: go,
  [RouteId.INVITE_ACCEPT_BOARD]: inviteAcceptBoard,
  [RouteId.INVITE_ACCEPT_TEAM]: inviteAcceptTeam,
  [RouteId.MEMBER_ACTIVITY]: memberActivity,
  [RouteId.OLD_MEMBER_ACTIVITY]: oldMemberActivity,
  [RouteId.MEMBER_ALL_BOARDS]: memberAllBoards,
  [RouteId.OLD_MEMBER_ALL_BOARDS]: oldMemberAllBoards,
  [RouteId.MEMBER_CARDS]: memberCards,
  [RouteId.OLD_MEMBER_CARDS]: oldMemberCards,
  [RouteId.MEMBER_CARDS_FOR_ORG]: memberCardsForOrg,
  [RouteId.OLD_MEMBER_CARDS_FOR_ORG]: oldMemberCardsForOrg,
  [RouteId.MEMBER_HOME]: memberHome,
  [RouteId.MEMBER_HOME_WORKSPACE_BOARDS]: memberHomeBoards,
  [RouteId.OLD_MEMBER_HOME_WORKSPACE_BOARDS]: oldMemberHomeBoards,
  [RouteId.MEMBER_PROFILE_SECTION]: memberProfile,
  [RouteId.MEMBER_TASKS]: memberTasks,
  [RouteId.MEMBER_LABS]: memberLabs,
  [RouteId.OLD_MEMBER_LABS]: oldMemberLabs,
  [RouteId.OLD_ORGANIZATION_GUESTS]: oldOrganizationGuests,
  [RouteId.OLD_ORGANIZATION_MEMBERS]: oldOrganizationMembers,
  [RouteId.OLD_ORGANIZATION_REQUESTS]: oldOrganizationRequests,
  [RouteId.ORGANIZATION_BOARDS]: organizationBoards,
  [RouteId.ORGANIZATION_BY_ID]: organizationById,
  [RouteId.ORGANIZATION_EXPORT]: organizationExport,
  [RouteId.OLD_ORGANIZATION_EXPORT]: oldOrganizationExport,
  [RouteId.ORGANIZATION_FREE_TRIAL]: organizationFreeTrial,
  [RouteId.OLD_ORGANIZATION_FREE_TRIAL]: oldOrganizationFreeTrial,
  [RouteId.ORGANIZATION_GUESTS]: organizationGuests,
  [RouteId.ORGANIZATION_REQUESTS]: organizationRequests,
  [RouteId.ORGANIZATION_MEMBER_CARDS]: organizationMemberCards,
  [RouteId.ORGANIZATION_MEMBERS]: organizationMembers,
  [RouteId.ORGANIZATION_POWER_UPS]: organizationPowerUps,
  [RouteId.OLD_ORGANIZATION_POWER_UPS]: oldOrganizationPowerUps,
  [RouteId.ORGANIZATION_TABLES]: organizationTables,
  [RouteId.OLD_ORGANIZATION_TABLES]: oldOrganizationTables,
  [RouteId.POWER_UP_ADMIN]: powerUpAdmin,
  [RouteId.POWER_UP_EDIT]: powerUpEdit,
  [RouteId.POWER_UP_PUBLIC_DIRECTORY]: powerUpPublicDirectory,
  [RouteId.PROFILE]: profile,
  [RouteId.REDEEM]: redeem,
  [RouteId.SEARCH]: search,
  [RouteId.OPEN_SOURCE_ATTRIBUTIONS]: openSourceAttributionsPage,
  [RouteId.SELECT_ORG_TO_UPGRADE]: selectOrgToUpgrade,
  [RouteId.SELECT_TEAM_TO_UPGRADE]: selectTeamToUpgrade,
  [RouteId.SHORTCUTS]: shortcuts,
  [RouteId.SHORTCUTS_OVERLAY]: shortcutsOverlay,
  [RouteId.ORGANIZATION_GETTING_STARTED]: teamGettingStarted,
  [RouteId.OLD_ACCOUNT]: oldAccount,
  [RouteId.OLD_ORGANIZATION_GETTING_STARTED]: oldTeamGettingStarted,
  [RouteId.ORGANIZATION_HIGHLIGHTS]: teamHighlights,
  [RouteId.OLD_ORGANIZATION_HIGHLIGHTS]: oldTeamHighlights,
  [RouteId.ORGANIZATION_REPORTS]: teamReports,
  [RouteId.OLD_ORGANIZATION_REPORTS]: oldTeamReports,
  [RouteId.TEMPLATES]: templates,
  [RouteId.TEMPLATES_RECOMMEND]: templatesRecommend,
  [RouteId.TO]: to,
  [RouteId.USER_OR_ORG]: userOrOrg,
  [RouteId.WORKSPACE_BILLING]: workspaceBilling,
  [RouteId.WORKSPACE_DEFAULT_CUSTOM_TABLE_VIEW]:
    workspaceDefaultCustomTableView,
  [RouteId.OLD_WORKSPACE_DEFAULT_CUSTOM_TABLE_VIEW]:
    oldWorkspaceDefaultCustomTableView,
  [RouteId.WORKSPACE_VIEW]: workspaceView,
  [RouteId.WORKSPACE_DEFAULT_CUSTOM_CALENDAR_VIEW]:
    workspaceDefaultCustomCalendarView,
  [RouteId.OLD_WORKSPACE_DEFAULT_CUSTOM_CALENDAR_VIEW]:
    oldWorkspaceDefaultCustomCalendarView,
} as const;

export type Routes = typeof routes;
export type RouteDefinition = Routes[RouteIdType];

/**
 * This represents the order in which the paths are matched by the router
 */
export const orderedRouteList: (typeof routes)[RouteIdType][] = [
  /**
   * Quick Boards
   */
  routes.go,
  routes.to,
  routes.doubleSlash,
  /**
   * Power Ups
   */
  routes.powerUpAdmin,
  routes.powerUpEdit,
  routes.powerUpPublicDirectory,
  /**
   * Other
   */
  routes.createFirstTeam,
  routes.shortcuts,
  routes.shortcutsOverlay,
  routes.blank,
  routes.selectOrgToUpgrade,
  routes.selectTeamToUpgrade,
  routes.search,
  routes.openSourceAttributionsPage,
  routes.templates,
  routes.templatesRecommend,
  routes.redeem,
  routes.createWorkspace,
  /**
   * Invitation
   */
  routes.inviteAcceptBoard,
  routes.inviteAcceptTeam,
  /**
   * Board
   */
  routes.boardOld,
  routes.boardReferral,
  routes.board,
  routes.cardAndBoardOld,
  routes.cardOld,
  routes.card,
  routes.createFirstBoard,
  /**
   * User or Org
   */
  routes.account,
  routes.oldAccount,
  routes.profile,
  routes.workspaceBilling,
  routes.billing,
  routes.userOrOrg,
  /**
   * Enterprise
   */
  routes.enterpriseAdmin,
  routes.enterpriseAdminTab,
  /**
   * Workspace View
   */
  routes.workspaceView,
  /**
   * Member
   */
  routes.memberHome,
  routes.memberHomeBoards,
  routes.oldMemberHomeBoards,
  routes.teamHighlights,
  routes.oldTeamHighlights,
  routes.teamGettingStarted,
  routes.oldTeamGettingStarted,
  routes.teamReports,
  routes.oldTeamReports,
  routes.memberAllBoards,
  routes.oldMemberAllBoards,
  routes.memberCards,
  routes.oldMemberCards,
  routes.memberCardsForOrg,
  routes.oldMemberCardsForOrg,
  routes.memberActivity,
  routes.oldMemberActivity,
  routes.memberLabs,
  routes.oldMemberLabs,
  routes.memberProfile,
  routes.memberTasks,
  /**
   * Organization
   */
  routes.organizationBoards,
  routes.oldOrganizationGuests,
  routes.oldOrganizationRequests,
  routes.oldOrganizationMembers,
  routes.organizationById,
  routes.organizationGuests,
  routes.organizationRequests,
  routes.organizationMembers,
  routes.organizationMemberCards,
  routes.organizationExport,
  routes.oldOrganizationExport,
  routes.organizationPowerUps,
  routes.oldOrganizationPowerUps,
  routes.organizationTables,
  routes.oldOrganizationTables,
  routes.organizationFreeTrial,
  routes.oldOrganizationFreeTrial,
  /**
   * Default Workspace Views
   */
  routes.workspaceView,
  routes.workspaceDefaultCustomTableView,
  routes.oldWorkspaceDefaultCustomTableView,
  routes.workspaceDefaultCustomCalendarView,
  routes.oldWorkspaceDefaultCustomCalendarView,
  /**
   * Catch all
   */
  routes.errorPage,
];
