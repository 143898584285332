export const nullOrString = (
  input: string | null | undefined,
): input is string | null => {
  return typeof input === 'string' || input === null;
};

export const isBool = (input: boolean | null | undefined): input is boolean => {
  return typeof input === 'boolean';
};

export const isNumber = (input: number | null | undefined): input is number => {
  return typeof input === 'number';
};

export const isString = (input: string | null | undefined): input is string => {
  return typeof input === 'string';
};
