import type { TrelloCardFields } from './mapTrelloCardDataToCard';
import {
  validateCardMemberData,
  type MemberData,
} from './validateCardMemberData';
import { nullOrString } from './validateHelpers';
import {
  validateTrelloCardBadgesData,
  type Badges,
} from './validateTrelloCardBadgesData';
import {
  validateTrelloCardBoardData,
  type TrelloCardBoardFields,
} from './validateTrelloCardBoardData';
import {
  validateTrelloCardCoverData,
  type Cover,
} from './validateTrelloCardCoverData';
import {
  validateTrelloCardCustomFieldItemsData,
  type CustomFieldItems,
} from './validateTrelloCardCustomFieldItemsData';
import { validateTrelloListData, type List } from './validateTrelloListData';
import {
  validateTrelloStickerData,
  type TrelloStickerFields,
} from './validateTrelloStickerData';

export const validateTrelloCardData = (inputCard: {
  objectId?: string;
  badges?: Badges;
  board?: TrelloCardBoardFields;
  closed?: boolean | null;
  cover?: Cover | null;
  customFieldItems?: CustomFieldItems | null;
  isTemplate?: boolean | null;
  labels?: {
    edges: {
      node: {
        color?: string | null;
        name?: string | null;
        objectId?: string | null;
      };
    }[];
  } | null;
  list?: List | null;
  mirrorSourceId?: string | null;
  name?: string | null;
  members?: MemberData | null;
  shortLink?: string | null;
  stickers?: TrelloStickerFields;
  role?: string | null;
}): inputCard is TrelloCardFields => {
  const {
    objectId,
    board,
    badges,
    closed,
    cover,
    customFieldItems,
    isTemplate,
    labels,
    list,
    members,
    mirrorSourceId,
    name,
    shortLink,
    stickers,
    role,
  } = inputCard;

  return (
    typeof objectId === 'string' &&
    typeof closed === 'boolean' &&
    typeof shortLink === 'string' &&
    validateTrelloCardBadgesData(badges) &&
    validateTrelloCardBoardData(board) &&
    ((!!cover && validateTrelloCardCoverData(cover)) || cover === null) &&
    validateTrelloCardCustomFieldItemsData(customFieldItems) &&
    typeof isTemplate === 'boolean' &&
    Array.isArray(labels?.edges) &&
    labels.edges.every((edge) => {
      const node = edge.node;
      return (
        typeof node.color === 'string' &&
        typeof node.name === 'string' &&
        typeof node.objectId === 'string'
      );
    }) &&
    ((!!list && validateTrelloListData(list)) || list === null) &&
    nullOrString(mirrorSourceId) &&
    ((!!members && validateCardMemberData(members)) || members === null) &&
    nullOrString(name) &&
    nullOrString(role) &&
    ((!!stickers && validateTrelloStickerData(stickers)) || stickers === null)
  );
};
