import { useCallback, useEffect } from 'react';

import { isMemberLoggedIn } from '@trello/authentication';
import { isEmbeddedDocument } from '@trello/browser';
import { useFeatureGate } from '@trello/feature-gate-client';
import type { RouteIdType } from '@trello/router';
import { RouteId, useIsActiveRoute } from '@trello/router';
import { useSharedState, useSharedStateSelector } from '@trello/shared-state';
import { workspaceNavigationHiddenState } from '@trello/workspace-navigation';
import { workspaceState } from '@trello/workspace-state';
import { usePreviousWhileLoading } from '@trello/workspaces';

import { workspaceNavigationErrorState } from './workspaceNavigationErrorState';

export const navHiddenRoutes = new Set<RouteIdType>([
  RouteId.MEMBER_HOME_WORKSPACE_BOARDS,
  RouteId.ORGANIZATION_HIGHLIGHTS,
  RouteId.OLD_ORGANIZATION_HIGHLIGHTS,
  RouteId.ORGANIZATION_REPORTS,
  RouteId.OLD_ORGANIZATION_REPORTS,
  RouteId.ORGANIZATION_GETTING_STARTED,
  RouteId.OLD_ORGANIZATION_GETTING_STARTED,
  RouteId.CREATE_FIRST_BOARD,
  RouteId.CREATE_FIRST_TEAM,
  RouteId.MEMBER_CARDS_FOR_ORG,
  RouteId.OLD_MEMBER_CARDS_FOR_ORG,
  RouteId.REDEEM,
  RouteId.BLANK,
  RouteId.CREATE_WORKSPACE,
  RouteId.DOUBLE_SLASH,
  RouteId.ENTERPRISE_ADMIN_TAB,
  RouteId.ENTERPRISE_ADMIN,
  RouteId.ERROR_PAGE,
  RouteId.GO,
  RouteId.INVITE_ACCEPT_TEAM,
  RouteId.MEMBER_ALL_BOARDS,
  RouteId.MEMBER_ACTIVITY,
  RouteId.OLD_MEMBER_ACTIVITY,
  RouteId.OLD_MEMBER_ALL_BOARDS,
  RouteId.MEMBER_CARDS,
  RouteId.OLD_MEMBER_CARDS,
  RouteId.MEMBER_HOME,
  RouteId.MEMBER_PROFILE_SECTION,
  RouteId.MEMBER_TASKS,
  RouteId.MEMBER_LABS,
  RouteId.OLD_MEMBER_LABS,
  RouteId.POWER_UP_ADMIN,
  RouteId.POWER_UP_EDIT,
  RouteId.POWER_UP_PUBLIC_DIRECTORY,
  RouteId.SEARCH,
  RouteId.OPEN_SOURCE_ATTRIBUTIONS,
  RouteId.SELECT_ORG_TO_UPGRADE,
  RouteId.SELECT_TEAM_TO_UPGRADE,
  RouteId.SHORTCUTS_OVERLAY,
  RouteId.SHORTCUTS,
  RouteId.TEMPLATES_RECOMMEND,
  RouteId.TEMPLATES,
  RouteId.TO,
]);

export const splitScreenHiddenRoutes = new Set<RouteIdType>([
  ...navHiddenRoutes,
  RouteId.CARD,
  RouteId.BOARD,
]);

export const additionalM2HiddenRoutes = new Set<RouteIdType>([
  RouteId.WORKSPACE_VIEW,
  RouteId.ORGANIZATION_TABLES,
  RouteId.WORKSPACE_DEFAULT_CUSTOM_TABLE_VIEW,
  RouteId.WORKSPACE_DEFAULT_CUSTOM_CALENDAR_VIEW,
  RouteId.OLD_WORKSPACE_DEFAULT_CUSTOM_TABLE_VIEW,
  RouteId.OLD_ORGANIZATION_TABLES,
  RouteId.OLD_WORKSPACE_DEFAULT_CUSTOM_CALENDAR_VIEW,
  RouteId.INVITE_ACCEPT_BOARD,
  RouteId.INVITE_ACCEPT_TEAM,
]);

export function useWorkspaceNavigationHiddenStateUpdater() {
  const [navHiddenState, setNavHiddenState] = useSharedState(
    workspaceNavigationHiddenState,
  );
  const { value: isSplitScreenEnabled } = useFeatureGate(
    'trello_personal_productivity_release',
  );
  const { value: isM2WorkspacePagesEnabled } = useFeatureGate(
    'billplat_m2_workspace_pages',
  );

  let blocklist = navHiddenRoutes;
  if (isSplitScreenEnabled) {
    blocklist = new Set([...blocklist, ...splitScreenHiddenRoutes]);
  }
  if (isM2WorkspacePagesEnabled) {
    blocklist = new Set([...blocklist, ...additionalM2HiddenRoutes]);
  }

  const [navErrorState] = useSharedState(workspaceNavigationErrorState);
  const isHiddenRoute = useIsActiveRoute(blocklist);

  const workspace = useSharedStateSelector(
    workspaceState,
    useCallback(
      (state) => ({
        isGlobal: state.isGlobal,
        isLoading: state.isLoading,
      }),
      [],
    ),
  );

  const isGlobal = usePreviousWhileLoading(
    workspace.isGlobal,
    workspace.isLoading,
    false,
  );

  const hideWorkspaceNav =
    isEmbeddedDocument() ||
    isHiddenRoute ||
    isGlobal ||
    navErrorState.hasError ||
    !isMemberLoggedIn();

  useEffect(() => {
    if (hideWorkspaceNav !== navHiddenState.hidden) {
      setNavHiddenState({ hidden: hideWorkspaceNav });
    }
  }, [hideWorkspaceNav, navHiddenState.hidden, setNavHiddenState]);
}
