import { useMemo } from 'react';

import { isEmbeddedDocument } from '@trello/browser';
import { useFeatureGate } from '@trello/feature-gate-client';
import type { RouteIdType } from '@trello/router';
import { RouteId, useIsActiveRoute } from '@trello/router';

const HEADER_ROUTES_BLOCKLIST = new Set<RouteIdType>([
  RouteId.CREATE_FIRST_BOARD,
  RouteId.CREATE_FIRST_TEAM,
  RouteId.REDEEM,
]);

const FULL_SCREEN_WORKSPACE_ROUTES = new Set<RouteIdType>([
  RouteId.ORGANIZATION_BY_ID,
  RouteId.ORGANIZATION_BOARDS,
  RouteId.ORGANIZATION_MEMBERS,
  RouteId.ORGANIZATION_GUESTS,
  RouteId.ORGANIZATION_REQUESTS,
  RouteId.ACCOUNT,
  RouteId.ORGANIZATION_EXPORT,
  RouteId.ORGANIZATION_POWER_UPS,
  RouteId.WORKSPACE_BILLING,
  RouteId.ORGANIZATION_FREE_TRIAL,
  RouteId.WORKSPACE_VIEW,
  RouteId.ORGANIZATION_TABLES,
  RouteId.WORKSPACE_DEFAULT_CUSTOM_TABLE_VIEW,
  RouteId.WORKSPACE_DEFAULT_CUSTOM_CALENDAR_VIEW,
  RouteId.OLD_WORKSPACE_DEFAULT_CUSTOM_TABLE_VIEW,
  RouteId.OLD_ORGANIZATION_TABLES,
  RouteId.OLD_WORKSPACE_DEFAULT_CUSTOM_CALENDAR_VIEW,
]);

const BOARD_OR_CARD_ROUTES = new Set<RouteIdType>([
  RouteId.BOARD,
  RouteId.CARD,
]);

/** The Header should be hidden on certain routes. */
export const useShouldRenderHeader = () => {
  const { value: isM2WorkspaceNavEnabled } = useFeatureGate(
    'billplat_m2_workspace_pages',
  );

  const blocklist = isM2WorkspaceNavEnabled
    ? new Set([...HEADER_ROUTES_BLOCKLIST, ...FULL_SCREEN_WORKSPACE_ROUTES])
    : new Set(HEADER_ROUTES_BLOCKLIST);

  const isRouteThatHidesHeader = useIsActiveRoute(blocklist);
  const isBoardOrCardRoute = useIsActiveRoute(BOARD_OR_CARD_ROUTES);
  const isEmbeddedOnBoard = useMemo(
    () => isEmbeddedDocument() && isBoardOrCardRoute,
    [isBoardOrCardRoute],
  );

  return !isRouteThatHidesHeader && !isEmbeddedOnBoard;
};
